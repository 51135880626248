// vendor modules
import React from 'react'
import T from 'prop-types'
import { withRouter } from 'react-router-dom'
import deburr from 'lodash/deburr'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
// components
import { Context } from '../Context'

function renderInputComponent (inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps

  return (
    <InputBase
      classes={{ input: classes.input }}
      inputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        }
      }}
      {...other}
    />
  )
}

const renderSuggestion = (classes) => (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.label, query)
  const parts = parse(suggestion.label, matches)

  const replaceSpaceByEntity = (str) => str.replace(' ', '&nbsp;')

  return (
    <MenuItem
      classes={{ root: classes.suggestionMenuItemRoot }}
      component='div'
      selected={isHighlighted}
    >
      <React.Fragment>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 700 }} dangerouslySetInnerHTML={{ __html: replaceSpaceByEntity(part.text) }} />
          ) : (
            <strong key={String(index)} style={{ fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: replaceSpaceByEntity(part.text) }} />
          )
        )}
      </React.Fragment>
    </MenuItem>
  )
}

function getSuggestions (suggestions, value) {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
      const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue

      if (keep) {
        count += 1
      }

      return keep
    })
}

function getSuggestionValue (suggestion) {
  return suggestion.label
}

const styles = theme => ({
  container: {
    position: 'relative'
  },
  input: {
    backgroundColor: '#fff',
    border: 0,
    borderRadius: '30px',
    fontSize: '14px',
    fontWeight: '600',
    marginRight: '.5rem',
    outline: 0,
    padding: '1rem 2rem',
    textTransform: 'uppercase',
    width: '500px'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block',
    margin: 0
  },
  suggestionMenuItemRoot: {
    fontSize: '14px !important',
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
})

class IntegrationAutosuggest extends React.Component {
  static propTypes = {
    classes: T.object.isRequired,
    intl: T.object.isRequired,
    placeholder: T.string.isRequired,
    suggestions: T.array.isRequired
  }

  state = {
    single: '',
    popper: '',
    suggestions: []
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(this.props.suggestions, value)
    })
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue
    })
  }

  handleSuggestionSelected = (lang) => (event, { suggestion }) => {
    this.props.history.push(`${suggestion.to}`)
  }

  render () {
    const { classes, intl, placeholder } = this.props

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion: renderSuggestion(classes)
    }

    return (
      <Context.Consumer>
        {({ lang }) => (
          <div className={classes.root}>
            <Autosuggest
              {...autosuggestProps}
              onSuggestionSelected={this.handleSuggestionSelected(lang)}
              inputProps={{
                classes,
                placeholder: intl.formatMessage({ id: placeholder }),
                value: this.state.single,
                onChange: this.handleChange('single')
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion
              }}
              renderSuggestionsContainer={options => (
                <Fade in={!!options.children}>
                  <Paper {...options.containerProps} square>
                    {options.children}
                  </Paper>
                </Fade>
              )}
            />
          </div>
        )}
      </Context.Consumer>
    )
  }
}

export default withRouter(withStyles(styles)(injectIntl(IntegrationAutosuggest)))
