// vendor modules
import styled from 'styled-components'
// components
import { FeatureList, InitialFeatureList } from 'components/common/Pricing/Feature'

export const StyledFeatureList = styled(FeatureList)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 3rem;
`

export const StyledInitialFeatureList = styled(InitialFeatureList)`
  padding: 0 0 2rem;
`

export const FeatureColumn = styled.div`
  &:not(:first-child) {
    padding-left: 2rem;
  }
  &:not(:last-child) {
    padding-right: 2rem;
    border-right: 1px solid #f3f4f5;
  }
`

export const Pricing = styled.div`
  position: relative;
  background: #fff;
  border-radius: 1rem;
  width: 60%;
  padding: ${props => props.bigger ? '4rem 0' : '3rem 0'};
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin: 4rem auto 0;
`

export const Outer = styled.div`
  margin: 0 auto;
  text-align: center;
`

export const Price = styled.div`
  color: ${props => (props.theme.color.button.primary)};
  font-size: ${props => props.bigger ? '52px' : '40px'};
  font-weight: 600;
`

export const PriceCategory = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 1rem 0;
`

export const ActionOuter = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
`

export const ActionInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
