// vendor modules
import React from 'react'
import { Layout, Container } from 'components/common'
import Header from 'components/pages/DNS/Header'
import Benefits from 'components/common/Benefits'
import Separator from 'components/common/Separator'
import FeatureBlock from 'components/common/FeatureBlock'
import Footer from 'components/common/Footer'
import FaultToleranceIllustration from 'components/pages/DNS/FaultTolerance'
import HighPerformanceIllustration from 'components/pages/DNS/HighPerformance'
import IdnGtldIllustration from 'components/pages/DNS/IdnGtld'
import GeoRecordsIllustration from 'components/pages/DNS/GeoRecords'
import GreyBackground from 'components/common/Background/Grey'
import Pricing from 'components/pages/DNS/Pricing'

const benefits = [
  'dns.benefits.nologs',
  'dns.benefits.sla',
  'dns.benefits.unlimitedrequests',
  'dns.benefits.cancelanytime',
  'dns.benefits.fastest',
  'dns.benefits.allidngtld',
  'dns.benefits.ipv6',
  'dns.benefits.unlimitedrecords',
  'dns.benefits.support'
]

class DNSPage extends React.PureComponent {
  static propTypes = {}

  render () {
    return (
      <Layout>
        <Header />
        <Benefits list={benefits} />
        <Separator />
        <a name='content' /> {/* eslint-disable-line */}
        <FeatureBlock
          as={Container}
          left
          icon='fault'
          title='dns.fault.title'
          description='dns.fault.description'
          buttonText='action.orderNow'
          illustration={<FaultToleranceIllustration />}
        />
        <FeatureBlock
          as={Container}
          right
          icon='performance'
          title='dns.performance.title'
          description='dns.performance.description'
          buttonText='action.orderNow'
          illustration={<HighPerformanceIllustration />}
        />
        <FeatureBlock
          as={Container}
          left
          icon='idn'
          title='dns.idn.title'
          description='dns.idn.description'
          buttonText='action.orderNow'
          illustration={<IdnGtldIllustration />}
        />
        <FeatureBlock
          as={Container}
          right
          icon='geo'
          title='dns.geo.title'
          description='dns.geo.description'
          buttonText='action.orderNow'
          illustration={<GeoRecordsIllustration />}
        />
        <GreyBackground>
          <Pricing />
        </GreyBackground>
        <Footer />
      </Layout>
    )
  }
}

export default DNSPage
