// vendor modules
import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { FaChevronDown } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
// components
import SearchInput from 'components/common/SearchInput'
import { Container, Content, Header, Hello, Search, StyledAvatar, User, UserName } from './styles'

class MainContent extends React.Component {
  state = {
    anchorEl: null
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { children } = this.props
    const { anchorEl } = this.state

    return (
      <Container>
        <Header>
          <Search>
            <SearchInput suggestions={[]} placeholder='manager.search.placeholder' />
          </Search>
          <ClickAwayListener onClickAway={this.handleClose}>
            <User
              aria-owns={anchorEl ? 'user-menu' : undefined}
              aria-haspopup='true'
              onClick={this.handleClick}
            >
              <StyledAvatar>H</StyledAvatar>
              <Hello><FormattedMessage id='manager.user.hello' /></Hello>
              <UserName>Jonathan Gautheron</UserName>
              <FaChevronDown />
            </User>
          </ClickAwayListener>
          <Menu
            id='user-menu'
            anchorEl={anchorEl}
            disableAutoFocusItem
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                width: 250
              }
            }}
          >
            <MenuItem onClick={this.handleClose}><FormattedMessage id='manager.user.menu.account' /></MenuItem>
            <MenuItem onClick={this.handleClose}><FormattedMessage id='manager.user.menu.logout' /></MenuItem>
          </Menu>
        </Header>
        <Content>
          {children}
        </Content>
      </Container>
    )
  }
}

export default injectIntl(MainContent)
