// vendor modules
import React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'

const StyledFeature = styled.li`
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  svg {
    background: #00dc3e;
    border-radius: 50%;
    fill: #fff;
    width: 25px;
    height: 25px;
    margin-right: 1rem;
    padding: .3rem;
  }
`

export const FeatureList = styled.div`
  list-style: none;
  margin: 0;
`

export const InitialFeatureList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 3rem;
`

export const AdditionalFeatureList = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  background: ${props => (props.theme.color.gradient.grey2)};
  border-radius: 1rem;
  padding: 2rem 1.5rem 0;
  margin: 2rem 1.5rem 0;
  &:before {
    content: url('/icons/plus.png');
    background: ${props => (props.theme.color.icon.green)};
    border-radius: 50%;
    position: absolute;
    left: 1rem;
    top: -20px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Feature = ({ children }) => {
  return (
    <StyledFeature><FaCheck />{children}</StyledFeature>
  )
}
