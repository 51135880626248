// vendor modules
import React, { useState, useEffect } from 'react'
// components
import { SignalContainer, Bars, Bar, Service, CountryName } from './styles'

const s1 = 24
const s2 = 19
const s3 = 14
const barSizes = [s3, s2, s1]

const Signal = props => {
  const [anim, setAnim] = useState({ [s1]: false, [s2]: false, [s3]: false })
  useEffect(() => {
    const timeouts = []
    if (props.show) {
      barSizes.slice(0, props.power).forEach((k, idx) => {
        timeouts.push(setTimeout(setAnim, 400 + idx * 500, { [s1]: k >= s1, [s2]: k >= s2, [s3]: k >= s3 }))
      })
    }
    return () => {
      timeouts.forEach(t => clearTimeout(t))
    }
  }, [props.show, props.power])
  return (
    <SignalContainer {...props}>
      <Bars {...props}>
        {[s1, s2, s3].map(h => (
          <Bar key={`el-${h}`} on={anim[h]} height={h} />
        ))}
      </Bars>
      <CountryName>{props.country}</CountryName>
      <Service>{props.service}</Service>
    </SignalContainer>
  )
}

export default Signal
