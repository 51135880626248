// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'
import positionMixin from '../../../../util/positionMixin'

const GreenBlock = posed.div({
  animated: { opacity: 1, scale: 1, transition: { type: 'spring' } },
  idle: { opacity: 0, scale: 0.8 }
})

export const StyledGreenBlock = styled(GreenBlock)`
  background: ${props => props.theme.color.icon.green};
  color: #fff;
  padding: 0.7rem 1.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  svg {
    color: #fff;
    opacity: 0.8;
    margin-right: 0.5rem;
  }
`

export const StyledRedBlock = styled(StyledGreenBlock)`
  background: ${props => props.theme.color.icon.red};
`

export const Outer = styled.div`
  height: 100%;
`

export const Inner = styled.div`
  position: relative;
  height: 100%;
`

const WindowDescriptor = posed.div({
  animated: {
    delayChildren: 2000,
    staggerChildren: 700
  },
  idle: { delay: 900 }
})

export const StyledWindowDescriptor = styled(WindowDescriptor)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: -35px;
  right: -25px;
  & > div {
    margin-left: 0.5rem;
  }
`

export const WindowInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-right: 1rem;
  }
  span {
    opacity: 0.8;
  }
`

export const WindowInnerDivider = styled.hr`
  margin: 1em -1.5rem 1em 0;
  background: #eee;
`

export const WindowOuter = styled.div`
  position: relative;
`

export const WindowWrapper = styled.div(positionMixin)
