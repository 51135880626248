// vendor modules
import React from 'react'
import styled from 'styled-components'
import Ink from 'react-ink'
// components
import Link from '../Link'

const LinkButton = styled(Link)`
    position: relative;
    color: #fff !important;
    padding: .6rem 2.5rem;
    background: ${props => (props.theme.color.button.primary)};
    border-radius: 2rem;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .025em;
    text-decoration: none;
    cursor: pointer;
    min-height: 3rem;
    transition: all .5s;
    white-space: nowrap;
    display: flex;
    align-items: center;
    i {
        transition: all .3s;
    }
    &:focus {
        outline: none;
    }
    &:hover i {
        color: #fff;
    }
    svg {
        color: ${props => props.theme.color.icon.secondary};
        ${({ rightIcon }) => rightIcon && `
          margin-left: .6rem;
        `}
        ${({ rightIcon }) => !rightIcon && `
          margin-right: .6rem;
        `}
    }
    span + img {
        margin-left: .6rem;
    }
    &:disabled {
      svg {
        color: #fff;
      }
    }
`

const StyledButton = styled(LinkButton)`
    &:hover {
        background: ${props => (props.theme.color.button.secondary)};
    }
`

export const Button = (props) => (
  <StyledButton {...props}>
    <Ink />
    {props.children}
  </StyledButton>
)

const StyledGradientButton = styled(LinkButton)`
    background: linear-gradient(102deg, rgb(22, 31, 54) 0%, #2d3eb1 78%, #2d3eb1 100%);
    background-size: 200% auto;
    &:hover {
        background-position: right center;
    }
`

export const GradientButton = (props) => (
  <StyledGradientButton {...props}>
    <Ink />
    {props.children}
  </StyledGradientButton>
)

const StyledGradientActionButton = styled.button`
  &:disabled {
    background: #ccc;
  }
`

export const GradientActionButton = (props) => (
  <GradientButton as={StyledGradientActionButton} {...props}>
    <Ink />
    {props.children}
  </GradientButton>
)

export const ActionBasicButton = (props) => (
  <StyledButton as={StyledGradientActionButton} {...props}>
    <Ink />
    {props.children}
  </StyledButton>
)

const StyledWhiteActionButton = styled.button`
  background: #fff;
  color: ${props => props.theme.color.primary} !important;
  box-shadow: 0 0 50px rgba(0,0,0,0.1);
`

export const WhiteActionButton = (props) => (
  <GradientButton as={StyledWhiteActionButton} {...props}>
    <Ink />
    {props.children}
  </GradientButton>
)

const StyledGreenActionButton = styled.button`
  background: #00dc3e;
  color: #fff;
  svg {
     color: #fff;
  }
`

export const GreenActionButton = (props) => (
  <GradientButton as={StyledGreenActionButton} {...props}>
    <Ink />
    {props.children}
  </GradientButton>
)
