// vendor modules
import React from 'react'
import T from 'prop-types'
import { Container } from 'components/common'
import TabList from 'components/common/Tab/Horizontal'
import Menu from 'components/common/Menu'
import MenuSticky from 'components/common/MenuSticky'
// components
import { Actions, Fake, StyledHeader, Subtitle, TextWrapper, Title } from './styles'

const Header = ({ children, map, tabs, container: ChildrenContainer }) => (
  <StyledHeader>
    {map}
    <Menu />
    <MenuSticky />
    <ChildrenContainer>{children}</ChildrenContainer>
    {tabs && <TabList tabs={tabs} />}
    <Fake />
  </StyledHeader>
)

Header.propTypes = {
  children: T.node.isRequired,
  container: T.elementType,
  map: T.node.isRequired,
  tabs: T.array
}

Header.defaultProps = {
  container: Container,
  tabs: []
}

export default Header
export const HeaderActions = Actions
export const HeaderSubtitle = Subtitle
export const HeaderTextWrapper = TextWrapper
export const HeaderTitle = Title
