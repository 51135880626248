// vendor modules
import React from 'react'
// components
import Pill, { TYPE_DARK, TYPE_DARKER, TYPE_LIGHT, TYPE_LIGHTER } from 'components/common/Pill'
import { FadeBg, Container, Map, Image } from './styles'

export default () => {
  return (
    <Container>
      <Map id='map'>
        <Pill height='60px' position={{ bottom: '130px', right: '-30px' }} type={TYPE_DARKER} width='290px' />
        <Pill height='60px' position={{ bottom: '230px', right: '220px' }} type={TYPE_DARK} width='230px' />
        <Pill height='60px' position={{ bottom: '250px', right: '-30px' }} type={TYPE_LIGHT} width='150px' />
        <Pill height='60px' position={{ bottom: '150px', left: '-30px' }} type={TYPE_LIGHT} width='250px' />
        <Pill height='60px' position={{ bottom: '155px', left: '300px' }} type={TYPE_LIGHTER} width='150px' />
        <Pill height='60px' position={{ bottom: '260px', left: '-50px' }} type={TYPE_LIGHT} width='100px' />
        <FadeBg />
        <Image src='/map.png' />
      </Map>
    </Container>
  )
}
