// vendor modules
import styled from 'styled-components'
// components
import { SmallText, Titleh2 } from '../../../../../Title'

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
`

export const Label = styled.div`
  color: ${props => props.theme.color.primary};
  font-size: 17px;
  font-weight: 600;
  padding-bottom: .5rem;
`

export const Separator = styled.hr`
  height: 1px;
  background: #f6f6f8;
  margin: 2rem 0;
`

export const StyledForm = styled.form`
  width: 100%;
`

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  ${({ half, two }) => (half || two) && `
    &>div {
      width: 49%;
    }
  `}
`

export const Paragraph = styled(SmallText)`
  color: #696d76;
  line-height: 30px;
`

export const SocialActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`

export const SocialButton = styled.button`
  width: 40px;
  height: 40px;
  background: #babcc7;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
  opacity: 0.7;
  transition: opacity .5s;
  svg {
    color: #fff;
  }
  &:hover {
    opacity: 1;
  }
`

export const SocialLogin = styled.div`
  margin-top: 2rem;
`

export const Title = styled(Titleh2)`
  text-align: left;
`
