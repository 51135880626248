// vendor modules
import React from 'react'
import { FaRegListAlt, FaRegMap, FaRegPaperPlane, FaRegUser } from 'react-icons/fa'
// components
import { FormattedMessage } from 'react-intl'
import Link from 'components/common/Link'
import { Container, Header, ItemList, Item, Logo, Navbar } from './styles'
import { ReactComponent as LogoVector } from 'images/logo-black.svg'

const links = [
  {
    icon: <FaRegListAlt />,
    label: 'manager.menu.dashboard',
    to: '/manager/dashboard'
  },
  {
    icon: <FaRegMap />,
    label: 'manager.menu.dns',
    to: '/manager/dns'
  },
  {
    icon: <FaRegPaperPlane />,
    label: 'manager.menu.vpn',
    to: '/manager/vpn'
  },
  {
    icon: <FaRegUser />,
    label: 'manager.menu.users',
    to: '/manager/users'
  }
]

const Menu = ({ children }) => (
  <Container>
    <Header>
      <Logo as={Link} to='/'>
        <LogoVector />
      </Logo>
    </Header>
    <Navbar>
      <ItemList>
        {links.map(item =>
          <Item key={item.label}><Link to={item.to} activeClassName='active'>{item.icon}<FormattedMessage id={item.label} /></Link></Item>
        )}
      </ItemList>
    </Navbar>
  </Container>
)

export default Menu
