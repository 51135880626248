// vendor modules
import React from 'react'
import styled from 'styled-components'
// components
import { Container } from 'components/common'

const Outer = styled.div`
  background: linear-gradient(to right, rgba(241,242,244,1) 0%,rgba(241,242,244,0) 100%);
`

const Inner = styled.div`
  margin: 0 auto;
  padding: 6rem 0;
`

export default (props) => (
  <Outer>
    <Inner as={Container}>
      {props.children}
    </Inner>
  </Outer>
)
