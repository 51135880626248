// vendor modules
import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { FaRegClock } from 'react-icons/fa'
// components
import Link from '../../Link'
import { SmallText, Titleh6 } from '../../Title'

const Outer = styled.div`
  text-align: center;
`

const Details = styled(SmallText)`
  svg {
    color: ${props => (props.theme.color.icon.secondary)};
    margin-right: .5rem;
    position: relative;
    top: 2px;
  }
`

const TitleLink = styled(Link)`
  color: ${props => (props.theme.color.primary)} !important;
  border-bottom: 2px solid transparent;
  transition: border-color .2s;
  &:hover {
    border-color: ${props => (props.theme.color.button.primary)};
  }
`

export default ({ node: { id, frontmatter: { category, contentType, date, pathname, title } } }) => (
  <Outer>
    <TitleLink to={`/support/${contentType}/${category}/${pathname}`}><Titleh6>{title}</Titleh6></TitleLink>
    <Details>
      <FaRegClock />
      <FormattedDate value={date} year='numeric' month='long' day='numeric' />
      {' '}
      <FormattedMessage id='article.in' />
      {' '}
      <Link to={`/support/${contentType}/${category}/`}><FormattedMessage id={`support.${contentType}.${category}`} /></Link>
    </Details>
  </Outer>
)
