// vendor modules
import styled from 'styled-components'

export const Tabs = styled.div`
  position: relative;
`

export const Tab = styled.div`
  input[name="sections"] {
    visibility: hidden;
    position: absolute;
  }
  input[name="sections"]:checked + label { 
    background: linear-gradient(102deg, rgb(22, 31, 54) 0%, #2d3eb1 78%, #2d3eb1 100%);
    background-size: 100% auto;
    color: #fff;
    &:after {
      content: url('/icons/right-arrow.png');
      position: absolute;
      right: 10%;
    }
  }
  input[name="sections"]:checked ~ div {
    display: block;
  }
  &:not(:last-child) label {
    border-bottom: 1px solid #f8f8f9;
  }
`

export const Title = styled.label`
  position: relative;
  background: #fff;
  cursor: pointer;
  display: block;
  padding: 1.2rem 2rem;
  font-weight: 500;
  width: 32%;
  transition: all .5s;
  border-radius: .5rem;
`

export const Content = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 32%;
  padding-top: 1.5rem;
  padding-left: 3rem;
`
