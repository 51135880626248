// vendor modules
import styled from 'styled-components'

export const Global = styled.div`
  color: ${props => (props.theme.color.button.primary)};
  font-family: 'Montserrat', sans-serif;

  a {
    color: ${props => (props.theme.color.icon.secondary)};
    font-weight: 600;
    text-decoration: none;
    
    &.classic {
      border-bottom: 2px solid transparent;
      padding-bottom: 3px;
      transition: border-color .2s;

      &:hover {
        border-color: ${props => (props.theme.color.icon.secondary)};
      }
    }
  }
`
