// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'components/common/Link'
import { Icon, Label, Left, Outer, Right, Value } from './styles'

const KPI = ({ label, value, to, icon }) => {
  return (
    <Outer>
      <Left>
        <Label><FormattedMessage id={label} /></Label>
        <Value>{value}</Value>
      </Left>
      <Right>
        <Icon>{icon}</Icon>
        <Link to={to}><FormattedMessage id='manager.dashboard.manage' /></Link>
      </Right>
    </Outer>
  )
}

export default KPI
