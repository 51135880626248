// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { FaDesktop, FaMapMarkerAlt } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import Typist from 'react-typist'
// components
import Pill, { TYPE_GREY_1, TYPE_GREY_2 } from 'components/common/Pill'
import Window from 'components/common/Illustration/Window'
import { Inner, Outer, StyledGreenBlock, StyledWindowDescriptor, WindowInner, WindowOuter, WindowWrapper } from './styles'

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ bottom: '400px', right: '150px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '350px', right: '550px' }} type={TYPE_GREY_2} width='350px' />
        <Pill height='110px' position={{ bottom: '150px', right: '-150px' }} type={TYPE_GREY_1} width='780px' />
        {' '}
        <Inner>
          <WindowWrapper position={{ top: '150px', right: '-50px' }}>
            <WindowOuter>
              <Window shouldAnimate={shouldAnimate}>
                <WindowInner>
                  <img src='/icons/browser-icon.png' alt='' />
                  {' '}
                  {shouldAnimate && <Typist startDelay={700}><span>callback.net</span></Typist>}
                </WindowInner>
              </Window>
              <StyledWindowDescriptor pose={shouldAnimate ? 'animated' : 'idle'}>
                <StyledGreenBlock><FaDesktop /> <FormattedMessage id='dns.fault.ip' values={{ ip: '178.122.90.1' }} /></StyledGreenBlock>
                <StyledGreenBlock><FaMapMarkerAlt /> <FormattedMessage id='dns.fault.from' values={{ country: 'US' }} /></StyledGreenBlock>
              </StyledWindowDescriptor>
            </WindowOuter>
          </WindowWrapper>
          <WindowWrapper position={{ bottom: '0px', right: '50px' }}>
            <WindowOuter>
              <Window shouldAnimate={shouldAnimate}>
                <WindowInner>
                  <img src='/icons/browser-icon.png' alt='' />
                  {' '}
                  {shouldAnimate && <Typist startDelay={700}><span>jp.callback.net</span></Typist>}
                </WindowInner>
              </Window>
              <StyledWindowDescriptor pose={shouldAnimate ? 'animated' : 'idle'}>
                <StyledGreenBlock><FaDesktop /> <FormattedMessage id='dns.fault.ip' values={{ ip: '94.51.76.36' }} /></StyledGreenBlock>
                <StyledGreenBlock><FaMapMarkerAlt /> <FormattedMessage id='dns.fault.from' values={{ country: 'Japan' }} /></StyledGreenBlock>
              </StyledWindowDescriptor>
            </WindowOuter>
          </WindowWrapper>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
