// vendor modules
import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import styled from 'styled-components'
import Header, { HeaderTitle, HeaderSubtitle, HeaderActions } from 'components/common/Header'
import { Button, GradientActionButton } from 'components/common'
import Map from './Map'

const VpnHeaderWrapper = styled.div`
  width: 80%;
`

const VpnTextWrapper = styled.div`
  width: 60%;
  max-width: 800px;
  min-width: 600px;
`

const VpnHeaderActions = styled(HeaderActions)`
  justify-content: flex-start;
`

const scrollToContent = () => {
  document.querySelector('a[name="content"]').scrollIntoView({ behavior: 'smooth' })
}

export default ({ tabs }) => (
  <Header map={<Map />} tabs={tabs} container={VpnHeaderWrapper}>
    <VpnTextWrapper>
      <HeaderTitle>
        <FormattedHTMLMessage id='home.title' />
      </HeaderTitle>
      <HeaderSubtitle>
        <FormattedHTMLMessage id='home.subtitle' />
      </HeaderSubtitle>
    </VpnTextWrapper>
    <VpnHeaderActions>
      <Button to='/vpn/pricing'>
        <FormattedMessage id='action.pricing' />
      </Button>
      <GradientActionButton onClick={scrollToContent}><FormattedMessage id='action.learnMore' /></GradientActionButton>
    </VpnHeaderActions>
  </Header>
)
