// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
// components
import Pill, { TYPE_GREY_1, TYPE_GREY_2 } from 'components/common/Pill'
// import User from './User'
import { Inner, Outer, StyledBackgroundMap, StyledDot } from './styles'

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ top: '250px', left: '0px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '400px', left: '550px' }} type={TYPE_GREY_2} width='350px' />
        <Pill height='110px' position={{ bottom: '200px', left: '-150px' }} type={TYPE_GREY_2} width='780px' />
        {' '}
        <Inner>
          <StyledBackgroundMap pose={shouldAnimate ? 'animated' : 'idle'}>
            <StyledDot position={{ top: '80px', left: '70px' }} />
            <StyledDot position={{ bottom: '95px', left: '210px' }} />
            <StyledDot position={{ bottom: '45px', right: '95px' }} />
            <StyledDot position={{ top: '70px', left: '290px' }} />
            <StyledDot position={{ top: '15px', left: '330px' }} />
            <StyledDot position={{ top: '75px', right: '125px' }} />
          </StyledBackgroundMap>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
