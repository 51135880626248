// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
// components
import { Icon } from '../../Icon/Green'
import { ActionLink, ContentWrapper, IconWrapper, StyledDescription, StyledTitle } from './styles'

export default ({ description, icon, title, to }) => (
  <ActionLink to={to}>
    <IconWrapper>
      <Icon><img src={`/icons/${icon}.png`} alt='' /></Icon>
    </IconWrapper>
    <ContentWrapper>
      <StyledTitle><FormattedMessage id={title} /></StyledTitle>
      <StyledDescription><FormattedMessage id={description} /></StyledDescription>
    </ContentWrapper>
  </ActionLink>
)
