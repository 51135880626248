// vendor modules
import React from 'react'
import T from 'prop-types'
import { FormattedMessage } from 'react-intl'
import TabList from 'components/common/Tab/Horizontal'
import Menu from 'components/common/Menu'
import MenuSticky from 'components/common/MenuSticky'
// components
import Map from './Map'

import { Content, Fake, Icon, StyledHeader, Title } from './styles'

const renderTitle = (title) => {
  if (typeof title === 'string') {
    return <Title><FormattedMessage id={title} /></Title>
  }
  return title
}

const SmallHeader = ({ children, height, icon, tabs, title }) => (
  <StyledHeader height={height}>
    <Map />
    <Menu />
    <MenuSticky />
    <Content>
      {icon && <Icon><img src={`/icons/${icon}.png`} alt='' /></Icon>}
      {title && renderTitle(title)}
      {children && children}
    </Content>
    {tabs && <TabList tabs={tabs} />}
    <Fake>{' '}</Fake>
  </StyledHeader>
)

SmallHeader.propTypes = {
  children: T.node,
  height: T.string,
  icon: T.string,
  tabs: T.array,
  title: T.any
}

SmallHeader.defaultProps = {
  children: null,
  height: '55vh',
  icon: '',
  tabs: [],
  title: ''
}

export default SmallHeader
