// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import Typist from 'react-typist'
import Pill, { TYPE_GREY_1 } from 'components/common/Pill'
import Window from 'components/common/Illustration/Window'
import {
  Inner,
  Outer,
  WindowInner,
  WindowInnerDivider,
  WindowOuter,
  WindowWrapper
} from '../../DNS/FaultTolerance/styles'

const sites = ['google', '-', 'youtube', '-', 'facebook']
const siteHashes = ['a$C1uR*dC!7f0w', '-', 'C10#G2t$9j2k7AxF2*d', '-', '8Fnci!w8%su4H5jzUI1x']

const withDivider = mapFn => (item, idx) => (item === '-' ? <WindowInnerDivider /> : mapFn(item, idx))

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ bottom: '400px', left: '-50px' }} type={TYPE_GREY_1} width='250px' />
        <Pill height='110px' position={{ bottom: '340px', left: '300px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '140px', left: '-50px' }} type={TYPE_GREY_1} width='390px' />
        <Inner>
          <WindowWrapper position={{ top: '0px', left: '-50px' }}>
            <WindowOuter>
              <Window shouldAnimate={shouldAnimate} fade>
                {sites.map(
                  withDivider((site, idx) => (
                    <WindowInner>
                      <img src={`/icons/${site}.png`} alt='' />{' '}
                      {shouldAnimate && (
                        <Typist startDelay={(idx + 1) * 1000}>
                          <span>https://{site}.com</span>
                        </Typist>
                      )}
                    </WindowInner>
                  ))
                )}
              </Window>
            </WindowOuter>
          </WindowWrapper>
          <WindowWrapper position={{ top: '200px', left: '120px' }}>
            <WindowOuter>
              <Window shouldAnimate={shouldAnimate} fade color='green'>
                {siteHashes.map(
                  withDivider((hash, idx) => (
                    <WindowInner>
                      <img src='/icons/safe.png' alt='' />{' '}
                      {shouldAnimate && (
                        <Typist startDelay={(idx + 1) * 1000}>
                          <span>{hash}</span>
                        </Typist>
                      )}
                    </WindowInner>
                  ))
                )}
              </Window>
            </WindowOuter>
          </WindowWrapper>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
