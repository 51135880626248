// vendor modules
import React from 'react'
import { FaCcVisa, FaCcMastercard, FaCcPaypal } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
// components
import { Container } from 'components/common'
import { Columns, Copyright, CopyrightWrapper, Fake, LinkColumn, Link, Links, PaymentMethods, Title, Wrapper } from './styles'
import Anchor from 'components/common/Link'
import { ReactComponent as Logo } from 'images/logo-black.svg'

export default () => {
  return (
    <Wrapper>
      <Columns>
        <Logo width='261px' />
        <LinkColumn>
          <Title><FormattedMessage id='footer.company.title' /></Title>
          <Links>
            <Link><Anchor to='/about-us'><FormattedMessage id='footer.company.aboutUs' /></Anchor></Link>
            <Link><Anchor to='/press'><FormattedMessage id='footer.company.press' /></Anchor></Link>
            <Link><Anchor to='/pages/jobs'><FormattedMessage id='footer.company.jobs' /></Anchor></Link>
            <Link><Anchor to='/pages/privacy-policy'><FormattedMessage id='footer.company.privacyPolicy' /></Anchor></Link>
            <Link><Anchor to='/pages/terms-of-service'><FormattedMessage id='footer.company.termsOfService' /></Anchor></Link>
          </Links>
        </LinkColumn>
        <LinkColumn>
          <Title><FormattedMessage id='support.title' /></Title>
          <Links>
            <Link><Anchor to='/support/kb'><FormattedMessage id='support.kb.title' /></Anchor></Link>
            <Link><Anchor to='/support/guides'><FormattedMessage id='support.guides.title' /></Anchor></Link>
            <Link><Anchor to='/support/news'><FormattedMessage id='support.news.title' /></Anchor></Link>
            <Link><Anchor to='/support/contact'><FormattedMessage id='support.contact.title' /></Anchor></Link>
          </Links>
        </LinkColumn>
        <LinkColumn>
          <Title>Join Us</Title>
          <Links>
            <Link><Anchor to='/'>Facebook</Anchor></Link>
            <Link><Anchor to='/'>Twitter</Anchor></Link>
          </Links>
        </LinkColumn>
        <Fake />
      </Columns>

      <CopyrightWrapper as={Container}>
        <Copyright>Copyright © 2019 Callback. All Rights reserved.</Copyright>
        <PaymentMethods>
          <FaCcVisa size='36px' />
          <FaCcMastercard size='36px' />
          <FaCcPaypal size='36px' />
        </PaymentMethods>
      </CopyrightWrapper>
    </Wrapper>
  )
}
