// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'

export const Actions = styled.div`
  min-width: 25vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  visibility: hidden;
  >*:not(:last-child) {
    margin-right: .5rem;
  }
`

const Links = posed.div({
  animated: {
    delayChildren: 600,
    staggerChildren: 150
  },
  idle: {}
})

export const StyledLinks = styled(Links)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  a {
    color: ${props => props.theme.color.primary} !important;
    text-decoration: none;
    font-size: .92rem;
    font-weight: 500;
    margin: 0 1.5rem;
    outline: none;
    display: flex;
    align-items: center;
    position: relative;
    .slider {
      position: absolute;
      height: 2px;
      background-color: #2f43e4;
      width: 0%;
      transition: width .5s;
      top: 2rem;
    }
    &:hover .slider {
      width: 100%;
    }
    &.active .slider {
      width: 100%;
    }
  }
  svg {
    color: ${props => props.theme.color.icon.primary};
    margin-left: .5rem;
  }
`

const Link = posed.span({
  animated: {
    opacity: 1,
    x: 0,
    transition: { duration: 600 }
  },
  idle: { opacity: 0, x: 10 }
})

export const StyledLink = styled(Link)`
`

const LogoAnimated = posed.div({
  animated: {
    opacity: 1,
    x: 0,
    transition: { duration: 800 }
  },
  idle: { opacity: 0, x: -10 }
})

export const StyledLogo = styled(LogoAnimated)`
`

export const Logo = styled.a`
  min-width: 25vw;
  display: block;
  text-decoration: none;
  font-weight: bold;
  outline: none;
  svg {
    width: 261px;
    height: 21px;
  }
`

export const Navbar = styled.nav`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  padding: 0 3rem;
`

const StickyWrapper = posed.div({
  animated: { opacity: 1, y: 0, transition: { ease: 'linear' } },
  idle: { opacity: 1, y: -100 }
})

export const StyledStickyWrapper = styled(StickyWrapper)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`
