// vendor modules
import React from 'react'
import T from 'prop-types'
import { injectIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
// components
import FW from '../../../../../../util/fw'
import Loader from '../../../../Loader'
import Form from './Form/'

class StepEmail extends React.Component {
  static propTypes = {
    intl: T.object.isRequired
  }

  state = {
    fields: [],
    loading: true
  }

  async componentDidMount () {
    const fw = await FW()
    const { data } = await fw.rest('User:flow', 'GET')
    const fields = data.fields
      .filter(field => field.type !== 'label')
      .map(field => ({ ...field, required: data.req.includes(field.name) }))
    this.setState({ fields, loading: false })
  }

  handleSubmit = async (formData) => {
    const fw = await FW()
    const { data } = await fw.rest('User:flow', 'POST', { ...formData, return_to: '/' })
    console.log(data)
  }

  get validationSchema () {
    const { formatMessage } = this.props.intl
    const validation = {}
    this.state.fields.forEach(field => {
      validation[field.name] = Yup.string(formatMessage({ id: `support.contact.form.validation.${field.name}.title` }))

      // Apply special types
      switch (field.type) {
        case 'email':
          validation[field.name] = validation[field.name]
            .email(formatMessage({ id: `support.contact.form.validation.email.format` }))
          break
        default:
          // do nothing
      }

      // Apply required
      if (field.required) {
        validation[field.name] = validation[field.name]
          .required(formatMessage({ id: `support.contact.form.validation.${field.name}.required` }))
      }
    })
    return Yup.object(validation)
  }

  render () {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <Formik
        render={props => <Form {...props} fields={this.state.fields} onSubmit={this.handleSubmit} />}
        validationSchema={this.validationSchema}
      />
    )
  }
}

export default injectIntl(StepEmail)
