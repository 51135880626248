// vendor modules
import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
// components
import { Actions, Content, Illustration, Inner, Outer } from './styles'
import { NormalText, Titleh1 as Title } from '../Title'
import { Container, GradientActionButton } from 'components/common'
import { Icon } from '../Icon/Green'

export default ({ left, right, icon, illustration, title, description, buttonLink, buttonText }) => {
  return (
    <Outer>
      <Inner as={Container}>
        {right && illustration && <Illustration>{illustration}</Illustration>}
        <Content align={left ? 'left' : 'right'}>
          <Icon>
            {typeof icon === 'string' ? <img src={`/icons/${icon}.png`} alt='' /> : icon}
          </Icon>
          <Title><FormattedHTMLMessage id={title} /></Title>
          <NormalText><FormattedMessage id={description} /></NormalText>
          <Actions>
            <GradientActionButton><FormattedMessage id={buttonText} /></GradientActionButton>
          </Actions>
        </Content>
        {left && illustration && <Illustration>{illustration}</Illustration>}
      </Inner>
    </Outer>
  )
}
