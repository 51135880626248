// vendor modules
import styled from 'styled-components'

export const Actions = styled.div`
  min-width: 25vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  >*:not(:last-child) {
    margin-right: .5rem;
  }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  a {
    color: #fff !important;
    text-decoration: none;
    font-size: .92rem;
    font-weight: 400;
    margin: 0 1.5rem;
    outline: none;
    display: flex;
    align-items: center;
    position: relative;
    .slider {
      position: absolute;
      height: 2px;
      background-color: #2f43e4;
      width: 0%;
      transition: width .5s;
      top: 2rem;
    }
    &:hover .slider {
      width: 100%;
    }
    &.active .slider {
      width: 100%;
    }
  }
  svg {
    color: ${props => props.theme.color.icon.primary};
    margin-left: .5rem;
  }
`

export const Logo = styled.a`
  min-width: 25vw;
  display: block;
  text-decoration: none;
  font-weight: bold;
  outline: none;
  svg {
    width: 261px;
    height: 21px;
  }
`

export const Navbar = styled.nav`
  display: flex;
  padding: 0 3rem;
  align-items: center;
  justify-content: space-between;
  min-height: 150px;
  width: 100%;
  ${({ sticky }) => sticky && `
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  `}
`
