// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import posed from 'react-pose'
import Pill, { TYPE_GREY_1 } from 'components/common/Pill'
import { Inner, Outer } from '../../DNS/FaultTolerance/styles'
import Icon from './icon'

const Anim = posed.div({
  animated: {
    staggerChildren: 900
  },
  idle: {}
})

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ bottom: '400px', right: '-50px' }} type={TYPE_GREY_1} width='250px' />
        <Pill height='110px' position={{ bottom: '340px', right: '250px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '140px', right: '-50px' }} type={TYPE_GREY_1} width='390px' />
        <Inner>
          <Anim pose={shouldAnimate ? 'animated' : 'idle'}>
            <Icon position={{ left: 90, top: 0 }} num={1} type='download' arrow='bottom' />
            <Icon position={{ left: 250, top: 180 }} num={2} type='install' arrow='top' />
            <Icon position={{ left: 410, top: 0 }} num={3} type='configure' arrow='bottom' />
            <Icon position={{ left: 570, top: 180 }} num={4} type='start' />
          </Anim>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
