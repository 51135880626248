// vendor modules
import React from 'react'
// components
import Tab from './Tab'
import { Outer, Inner } from './styles'

const TabList = ({ tabs }) => (
  <Outer>
    <Inner>
      {tabs.map((tab, idx) => <Tab key={idx} {...tab} />)}
    </Inner>
  </Outer>
)

export default TabList
