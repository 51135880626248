// vendor modules
import styled from 'styled-components'

export const Outer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

export const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`
