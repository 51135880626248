// vendor modules
import styled from 'styled-components'

export const Icon = styled.div`
    background: #e5fbeb;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    img, svg {
        max-width: 44px;
        max-height: 40px;
    }
`
