// vendor modules
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
// components
import { Container } from 'components/common'
import { Title, Wrapper } from './styles'
import VerticalTabs from 'components/common/Tab/Vertical'

const ProfessionalFeatures = props => {
  const { formatMessage } = props.intl
  const tabs = ['dedicated', 'configure', 'cross', 'smart']
    .map(tab => ({
      title: formatMessage({ id: `home.professionalFeatures.${tab}.title` }),
      content: formatMessage({ id: `home.professionalFeatures.${tab}.p` })
    }))

  return (
    <Wrapper as={Container}>
      <Title><FormattedMessage id='home.professionalFeatures.title' /></Title>
      <VerticalTabs tabs={tabs} />
    </Wrapper>
  )
}

export default injectIntl(ProfessionalFeatures)
