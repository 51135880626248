import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { persistReducer } from 'redux-persist'
import { getPrefix } from '@karpeleslab/klbfw'
import storage from 'redux-persist/lib/storage'

import intl from './intl/reducer'

export const history = createBrowserHistory({basename: getPrefix()})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}

const reducers = persistReducer(
  persistConfig,
  combineReducers({
    intl,
    router: connectRouter(history)
  }))

export function rootReducer (state, action) {
  return reducers(state, action)
}
