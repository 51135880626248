// vendor modules
import styled from 'styled-components'

export const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const FadeBg = styled(MapWrapper)`
  z-index: 0;
  background: linear-gradient(to right, rgba(27,38,79,0.95) 0%,rgba(30,42,96,0) 50%,rgba(32,45,108,0) 87%,rgba(33,46,112,0.5) 100%);
`

export const Image = styled.img`
  transition: opacity .5s;
`

export const Map = styled(MapWrapper)`
  background: #273690;
  color: #fff;
  overflow: hidden;
`
