import React from 'react'
import { Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import Provider from 'components/common/Layout/Provider'

import Routes from './Routes'
import { history } from 'redux/reducers'

const App = () => {
  const intl = useSelector(state => state.intl)

  return (
    <IntlProvider {...intl} key={`locale-${intl.locale}`}>
      <Provider lang={intl.locale} locale={intl.locale}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </IntlProvider>
  )
}

export default App
