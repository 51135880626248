// vendor modules
import React from 'react'
// components
import { Context } from '../Context'

const Provider = ({ children, lang, locale }) => (
  <Context.Provider
    value={{ lang, locale }}
  >
    {children}
  </Context.Provider>
)

export default Provider
