// vendor modules
import detectNearestBrowserLocale from 'detect-nearest-browser-locale'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import enData from './localizationData/en-US'
import deData from './localizationData/de-DE'

export const localizationData = {}
export const enabledLanguages = ['en', 'de']
export const preferredLanguage = detectNearestBrowserLocale(enabledLanguages)

moment.locale(preferredLanguage)

localizationData.en = {}
localizationData.en.locale = 'en-US'
localizationData.en.messages = enData

localizationData.de = {}
localizationData.de.locale = 'de-DE'
localizationData.de.messages = deData