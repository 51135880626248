// vendor modules
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import { FaArrowRight, FaFacebookF, FaGoogle, FaTwitter } from 'react-icons/fa'
// components
import { GradientActionButton } from '../../../../../'
import { Actions, Label, Paragraph, SocialActions, SocialButton, SocialLogin, StyledForm, TextFieldWrapper, Title } from './styles'

class Form extends React.Component {
  handleChange = name => e => {
    e.persist()
    this.props.handleChange(e)
    this.props.setFieldTouched(name, true, false)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit(this.props.values)
  }

  render () {
    const {
      fields,
      intl: { formatMessage },
      values,
      errors,
      isValid,
      touched
    } = this.props

    return (
      <StyledForm onSubmit={this.handleSubmit}>
        <Title><FormattedMessage id='modal.login.title' /></Title>
        <Paragraph><FormattedMessage id='modal.login.preamble' /></Paragraph>

        {fields.map(field =>
          <React.Fragment key={field.name}>
            <Label><FormattedMessage id={`modal.login.form.${field.name}.label`} /></Label>
            <TextFieldWrapper>
              <TextField
                fullWidth
                error={touched[field.name] && Boolean(errors[field.name])}
                helperText={touched[field.name] ? errors[field.name] : ''}
                placeholder={formatMessage({ id: `modal.login.form.${field.name}.placeholder` })}
                name={field.name}
                onChange={this.handleChange(field.name)}
                value={values[field.name]}
                variant='outlined'
              />
            </TextFieldWrapper>
          </React.Fragment>
        )}

        <SocialLogin>
          <Paragraph><FormattedMessage id='modal.login.social' /></Paragraph>
          <SocialActions>
            <SocialButton type='button'><FaFacebookF /></SocialButton>
            <SocialButton type='button'><FaGoogle /></SocialButton>
            <SocialButton type='button'><FaTwitter /></SocialButton>
          </SocialActions>
        </SocialLogin>

        <Actions>
          <GradientActionButton type='submit' disabled={!isValid} rightIcon>
            <FormattedMessage id='action.next' />
            {' '}
            <FaArrowRight />
          </GradientActionButton>
        </Actions>
      </StyledForm>
    )
  }
}

export default injectIntl(Form)
