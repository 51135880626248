// vendor modules
import React from 'react'
// components
import { Decoration } from './styles'

const Decorations = ({ width, height }) => (
  <>
    {/* right decorations */}
    <Decoration width={width * 0.3} height={width * 0.045} top={height * 0.9} right={-width * 0.05} />
    <Decoration width={width * 0.2} height={width * 0.045} top={height * 0.8} right={width * 0.2} />
    <Decoration light width={width * 0.1} height={width * 0.045} top={height * 0.75} right={width * 0.075} />
    <Decoration width={width * 0.2} height={width * 0.045} top={height * 0.55} right={-width * 0.15} />
    {/* left decorations */}
    <Decoration reverse width={width * 0.2} height={width * 0.045} top={height * 0.25} right={width * 0.94} />
    <Decoration reverse width={width * 0.3} height={width * 0.045} top={height * 0.7} right={width * 0.8} />
  </>
)

export default Decorations
