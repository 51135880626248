// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'
import positionMixin from '../../../../../util/positionMixin'

const Outer = posed.div({
  animated: { opacity: 1, scale: 1, transition: { type: 'spring' } },
  idle: { opacity: 0, scale: 0.8 }
})

export const StyledOuter = styled(Outer)(positionMixin)

export const GreenDot = styled.div`
  background: ${props => props.theme.color.icon.green};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
`

export const Inner = styled.div`
  position: relative;
  color: #fff;
  width: 60px;
  height: 60px;
  background: ${props => props.theme.color.icon.primary};
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.active ? 1 : 0.5)};
  z-index: 1;
`

export const WhiteBlock = styled.div`
  background: #fff;
  color: ${props => props.theme.color.icon.primary};
  padding: 0.7rem 1.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 200px;
  bottom: -20px;
  right: -170px;
  z-index: 0;

  svg {
    color: #fff;
    opacity: 0.8;
    margin-right: 0.5rem;
  }
`
