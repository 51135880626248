// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
// components
import Pill, { TYPE_GREY_1, TYPE_GREY_2 } from 'components/common/Pill'
import { Inner, Outer, StyledCircleList, StyledCircle, StyledTextBlock } from './styles'

const TYPE_IDN = 1
const TYPE_GTLD = 2

const domains = [
  { label: '.co.jp', type: TYPE_IDN, position: { bottom: '170px', right: '250px' } },
  { label: '.de', type: TYPE_IDN, position: { bottom: '200px', right: '120px' } },
  { label: '.co.uk', type: TYPE_IDN, position: { bottom: '50px', left: '120px' } },
  { label: '.fr', type: TYPE_IDN, position: { top: '230px', left: '50px' } },
  { label: '.pl', type: TYPE_IDN, position: { top: '120px', right: '250px' } },
  { label: '.design', type: TYPE_GTLD, position: { top: '150px', left: '100px' } },
  { label: '.cafe', type: TYPE_GTLD, position: { top: '230px', left: '200px' } },
  { label: '.technology', type: TYPE_GTLD, position: { top: '250px', left: '-100px' } },
  { label: '.agency', type: TYPE_GTLD, position: { bottom: '150px', left: '110px' } },
  { label: '.shop', type: TYPE_GTLD, position: { top: '210px', right: '150px' } },
  { label: '.vote', type: TYPE_GTLD, position: { top: '180px', right: '80px' } },
  { label: '.art', type: TYPE_GTLD, position: { bottom: '50px', right: '100px' } },
  { label: '.bank', type: TYPE_GTLD, position: { bottom: '80px', left: '30px' } }
]

const renderDomain = (domain) => {
  const colorTheme = domain.type === TYPE_IDN ? 'white' : 'green'
  return <StyledTextBlock key={domain.label} position={domain.position} colorTheme={colorTheme}>{domain.label}</StyledTextBlock>
}

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ bottom: '400px', right: '150px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '350px', right: '550px' }} type={TYPE_GREY_2} width='350px' />
        <Pill height='110px' position={{ bottom: '150px', right: '-150px' }} type={TYPE_GREY_1} width='780px' />
        {' '}
        <Inner>
          <StyledCircleList pose={shouldAnimate ? 'animated' : 'idle'}>
            <StyledCircle opacity={1} size='200px' />
            <StyledCircle opacity={0.75} size='400px' />
            <StyledCircle opacity={0.5} size='600px' />
            {domains.map(domain => renderDomain(domain))}
          </StyledCircleList>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
