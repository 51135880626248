// vendor modules
import styled from 'styled-components'

export const Container = styled.div`
    width: 1082px;
    margin: 0 auto;
    /* @media (min-width: 601px) {
        width: 90%;
    }
    @media (min-width: 993px) {
        width: 80%;
    } */
`
