// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'
import positionMixin from '../../../../util/positionMixin'

const BackgroundMap = posed.div({
  animated: {
    delayChildren: 800,
    staggerChildren: 200,
    opacity: 1,
    rotate: 0,
    x: 0,
    y: 10,
    transition: { duration: 800 }
  },
  idle: { delay: 300, opacity: 0, rotate: 3, x: 10, y: 10 }
})
const Dot = posed.div({
  animated: { y: 0, opacity: 1, scale: 1, transition: { type: 'spring' } },
  idle: { y: 20, opacity: 0, scale: 0.8 }
})

export const StyledBackgroundMap = styled(BackgroundMap)`
  position: relative;
  left: -100px;
  width: 646px;
  height: 270px;
  background: url(/small-map.png) no-repeat 0 0;
`

export const StyledDot = styled(Dot)`
  width: 25px;
  height: 25px;
  background: #fff;
  border: 5px solid #5861a6;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(88, 97, 166, 0.3);
  ${positionMixin}
`

export const Outer = styled.div`
  height: 100%;
`

export const Inner = styled.div`
  position: relative;
  left: -100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
