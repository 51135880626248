// vendor modules
import React from 'react'
import T from 'prop-types'
import { NavLink } from 'react-router-dom'

const Link = ({
  activeClassName, children, className, partiallyActive, to
}) => {
  return (
    <NavLink activeClassName={activeClassName} className={className} partiallyActive={partiallyActive} to={to}>
      {children}
    </NavLink>
  )
}

Link.propTypes = {
  activeClassName: T.string,
  children: T.node.isRequired,
  className: T.string,
  partiallyActive: T.bool,
  to: T.string.isRequired
}

Link.defaultProps = {
  activeClassName: null,
  className: null,
  partiallyActive: false
}

export default Link
