// vendor modules
import React from 'react'
// components
import LoginForm from './LoginForm'
import { ModalContainer } from './styles'

export default () => (
  <ModalContainer>
    <LoginForm />
  </ModalContainer>
)
