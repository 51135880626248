// vendor modules
import React from 'react'
import { Layout, Container } from 'components/common'
import Header from 'components/pages/VPN/Header'
import References from 'components/common/References'
import Benefits from 'components/common/Benefits'
import Separator from 'components/common/Separator'
import FeatureBlock from 'components/common/FeatureBlock'
import ProfessionalBenefits from 'components/pages/VPN/ProfessionalBenefits'
import ProfessionalFeatures from 'components/pages/VPN/ProfessionalFeatures'
import PrivacyIllustration from 'components/pages/VPN/Privacy'
import EncryptionIllustration from 'components/pages/VPN/Encryption'
import SetupIllustration from 'components/pages/VPN/Setup'
import DevicesIllustration from 'components/pages/VPN/Devices'
import Footer from 'components/common/Footer'

const benefits = [
  'home.benefits.nologs',
  'home.benefits.dnsipleak',
  'home.benefits.unlimitedbandwidth',
  'home.benefits.cancelanytime',
  'home.benefits.fastest',
  'home.benefits.killswitch',
  'home.benefits.encryption',
  'home.benefits.p2p',
  'home.benefits.ipv6',
  'home.benefits.upnp',
  'home.benefits.global',
  'home.benefits.support'
]

class VPNPage extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Header />
        <References />
        <Benefits list={benefits} />
        <a href='/' name='content'>{' '}</a>
        <Separator />
        <FeatureBlock
          as={Container}
          left
          icon='privacy'
          title='home.privacy.title'
          description='home.privacy.description'
          buttonText='home.privacy.cta'
          illustration={<PrivacyIllustration />}
        />
        <FeatureBlock
          as={Container}
          right
          icon='encryption'
          title='home.encryption.title'
          description='home.encryption.description'
          buttonText='home.encryption.cta'
          illustration={<EncryptionIllustration />}
        />
        <FeatureBlock
          as={Container}
          left
          icon='setup'
          title='home.setup.title'
          description='home.setup.description'
          buttonText='home.setup.cta'
          illustration={<SetupIllustration />}
        />
        <FeatureBlock
          as={Container}
          right
          icon='device'
          title='home.device.title'
          description='home.device.description'
          buttonText='home.device.cta'
          illustration={<DevicesIllustration />}
        />
        <ProfessionalBenefits />
        <ProfessionalFeatures />
        <Footer />
      </Layout>
    )
  }
}

export default VPNPage
