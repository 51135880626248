// vendor modules
import React from 'react'
import styled from 'styled-components'

const Separator = styled.hr`
    height: 1px;
    background: #f1f2f4;
    width: 100%;
`

export default () => {
  return (
    <Separator />
  )
}
