// vendor modules
import React from 'react'
// components
import { Layout } from 'components/common'
import Menu from 'components/pages/Layout/UserPanel/Menu'
import MainContent from 'components/pages/Layout/UserPanel/MainContent'
import UserPanelLayout from 'components/pages/Layout/UserPanel'
import Dashboard from 'components/pages/Dashboard'

const LoginPage = () => (
  <Layout>
        <UserPanelLayout>
      <Menu />
      <MainContent>
        <Dashboard />
      </MainContent>
    </UserPanelLayout>
  </Layout>
)

export default LoginPage
