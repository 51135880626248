// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FaRegUser, FaToolbox } from 'react-icons/fa'
// components
import { ActionBasicButton, GradientButton } from 'components/common'
import Link from 'components/common/Link'
import { Actions, Navbar, Logo, StyledLinks, StyledLink, StyledLogo, StyledStickyWrapper } from './styles'
import { ReactComponent as LogoVector } from 'images/logo-black.svg'

export default class Header extends React.PureComponent {
  state = {
    scroll: 0
  }

  componentDidMount () {
    const el = document.querySelector('nav')
    this.setState({ top: el.offsetTop, height: el.offsetHeight })
    window.addEventListener('scroll', this.handleScroll)
  }

  get active () {
    return this.state.scroll > this.state.top + 110
  }

  handleScroll = () => {
    this.setState({ scroll: window.scrollY })
  }

  render () {
    return (
      <StyledStickyWrapper pose={this.active ? 'animated' : 'idle'}>
        <Navbar>
          <StyledLogo>
            <Logo as={Link} to='/'>
              <LogoVector />
            </Logo>
          </StyledLogo>
          <StyledLinks pose={this.active ? 'animated' : 'idle'}>
            <StyledLink>
              <Link to='/dns' activeClassName='active' partiallyActive>
                <FormattedMessage id='navbar.dns' />
                <span className='slider' />
              </Link>
            </StyledLink>
            <StyledLink>
              <Link to='/vpn' activeClassName='active' partiallyActive>
                <FormattedMessage id='navbar.vpn' />
                {/* <FaChevronDown /> */}
                <span className='slider' />
              </Link>
            </StyledLink>
            <StyledLink>
              <Link to='/support' activeClassName='active' partiallyActive>
                <FormattedMessage id='navbar.support' />
                <span className='slider' />
              </Link>
            </StyledLink>
          </StyledLinks>
          <Actions>
            <ActionBasicButton onClick={this.handleLoginClick}><FaRegUser /> <FormattedMessage id='action.signUp' /></ActionBasicButton>
            <GradientButton to='/manager/dashboard'><FaToolbox /> <FormattedMessage id='action.manager' /></GradientButton>
          </Actions>
        </Navbar>
      </StyledStickyWrapper>
    )
  }
}
