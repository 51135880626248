// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'

export const Outer = styled.div`
  height: 100%;
`

const Inner = posed.div({
  animated: {
    delayChildren: 0,
    staggerChildren: 50
  },
  idle: { delay: 500 }
})

export const StyledInner = styled(Inner)`
  position: relative;
  height: 100%;
`
