// vendor modules
import React from 'react'
import styled from 'styled-components'

const References = styled.div`
    padding: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => (props.theme.color.bg.grey)};
    img {
        filter: grayscale(100%);
        opacity: 0.4;
        transition: all 1s;
        &:not(:last-child) {
            margin-right: 5vw;
        }
        &:hover {
            filter: grayscale(0%);
            opacity: 1;
        }
    }
    &:focus {
        outline: none;
    }
`

export default () => {
  return (
    <References>
      <img src='/references/ebay.png' alt='ebay' />
      <img src='/references/amazon.png' alt='ebay' />
      <img src='/references/visa.png' alt='ebay' />
      <img src='/references/citybank.png' alt='ebay' />
      <img src='/references/ebay.png' alt='ebay' />
    </References>
  )
}
