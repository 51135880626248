// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'
import { StyledGreenBlock } from '../../DNS/FaultTolerance/styles'

// ------------------------------------------ ARROWS
const ArrowBottomStatic = styled.img`
  position: absolute;
  margin: 0 auto;
  right 2.5em;
  top: -1em;
`
export const ArrowBottom = posed(ArrowBottomStatic)({
  animated: {
    opacity: 1,
    y: '-100%',
    x: 8,
    transition: { type: 'spring' }
  },
  idle: {
    y: '-150%',
    x: 8,
    opacity: 0
  }
})

const ArrowLeftStatic = styled.img`
  margin-right: 1em;
  margin-left: 1em;
`
export const ArrowLeft = posed(ArrowLeftStatic)({
  animated: {
    opacity: 1,
    x: '0%',
    transition: { type: 'spring' }
  },
  idle: {
    x: '50%',
    opacity: 0
  }
})

// ------------------------------------------ ARROWS CONTAINER
const ArrowsContainerStatic = styled.div`
  position: absolute;
  right: -25px;
  top: 395px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  ${StyledGreenBlock} + ${StyledGreenBlock} {
    margin-right: 0.5rem;
  }
`
export const ArrowsContainer = posed(ArrowsContainerStatic)({
  animated: {
    delayChildren: 3500,
    staggerChildren: 700
  },
  idle: {}
})

// ------------------------------------------ DB SHIELD ICON
const DbShieldStatic = styled.div`
  background: linear-gradient(102deg, rgb(22, 31, 54) 0%, #2d3eb1 78%, #2d3eb1 100%);
  height: 5em;
  width: 5em;
  text-align: center;
  line-height: 5em;
  position: relative;
  border-radius: 50%;
  img {
    vertical-align: middle;
    max-width: 40%;
  }
`
export const DbShield = posed(DbShieldStatic)({
  animated: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: { type: 'spring' }
  },
  idle: {
    opacity: 0,
    scale: 0.2,
    y: -10
  }
})
