// vendor modules
import React from 'react'
import { FaRegUser } from 'react-icons/fa'
// components
import { GreenDot, Inner, StyledOuter, WhiteBlock } from './styles'

export default ({ ip, position }) => (
  <StyledOuter position={position}>
    <Inner active={!!ip}>
      <FaRegUser />
      {ip && <GreenDot />}
    </Inner>
    {ip && <WhiteBlock>IP: {ip}</WhiteBlock>}
  </StyledOuter>
)
