// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { FaDesktop, FaMapMarkerAlt } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import Typist from 'react-typist'
import Pill, { TYPE_GREY_1, TYPE_GREY_2 } from 'components/common/Pill'
import Window from 'components/common/Illustration/Window'
import {
  Inner,
  Outer,
  StyledGreenBlock,
  StyledRedBlock,
  StyledWindowDescriptor,
  WindowInner,
  WindowOuter,
  WindowWrapper
} from '../../DNS/FaultTolerance/styles'

import { ArrowBottom, DbShield, ArrowsContainer, ArrowLeft } from './styles'

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ bottom: '400px', right: '450px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '350px', right: '750px' }} type={TYPE_GREY_2} width='290px' />
        <Pill height='110px' position={{ bottom: '197px', right: '100px' }} type={TYPE_GREY_2} width='750px' />
        <Pill height='110px' position={{ bottom: '197px', right: '-250px' }} type={TYPE_GREY_1} width='280px' />{' '}
        <Inner>
          <WindowWrapper position={{ top: '150px', right: '-50px' }}>
            <WindowOuter>
              <Window shouldAnimate={shouldAnimate}>
                <WindowInner>
                  <img src='/icons/google.png' alt='' />{' '}
                  {shouldAnimate && (
                    <Typist startDelay={700}>
                      <span>https://google.com</span>
                    </Typist>
                  )}
                </WindowInner>
              </Window>
              <StyledWindowDescriptor pose={shouldAnimate ? 'animated' : 'idle'}>
                <StyledRedBlock>
                  <FaDesktop /> <FormattedMessage id='vpn.privacy.ip' values={{ ip: '178.122.90.1' }} />
                </StyledRedBlock>
                <StyledRedBlock>
                  <FaMapMarkerAlt /> <FormattedMessage id='vpn.privacy.from' />
                </StyledRedBlock>
              </StyledWindowDescriptor>
            </WindowOuter>
          </WindowWrapper>
          <ArrowsContainer pose={shouldAnimate ? 'animated' : 'idle'}>
            <ArrowBottom src='/icons/gray-arrow-bottom.png' />
            <DbShield>
              <img src='/icons/db-shield.png' alt='' />
            </DbShield>
            <ArrowLeft src='/icons/gray-arrow-left.png' />
            <StyledGreenBlock>
              <FaDesktop /> <FormattedMessage id='vpn.privacy.ip' values={{ ip: '178.122.90.1' }} />
            </StyledGreenBlock>
            <StyledGreenBlock>
              <FaMapMarkerAlt /> <FormattedMessage id='vpn.privacy.from' />
            </StyledGreenBlock>
          </ArrowsContainer>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
