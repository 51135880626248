// vendor modules
import React, { useState, useRef, useEffect } from 'react'
// components
import { Country } from './styles'
import Signal from './Signal'

const Connection = ({ scene, countryA, countryB, power = 3, hideAfter = 0 }) => {
  const xDiff = countryA.x - countryB.x
  const yDiff = countryA.y - countryB.y
  const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff)
  const id = `country-${countryA.code}-${countryB.code}`

  const gradientDirection = xDiff <= 0 ? 'l2r' : 'r2l'

  const absXDiff = Math.abs(xDiff)
  const absYDiff = Math.abs(yDiff)

  const top = absXDiff > absYDiff

  const [len, setLen] = useState(0)
  const [pointsVisible, setPointsVisible] = useState({ a: false, b: false })
  const ref = useRef(null)

  const flattenCurve = 5

  useEffect(() => {
    const timeouts = []
    setLen(ref.current.getTotalLength())
    timeouts.push(setTimeout(setPointsVisible, 0, { a: true, b: false }))
    timeouts.push(setTimeout(setPointsVisible, 3000, { a: true, b: true }))

    if (hideAfter) {
      timeouts.push(setTimeout(setPointsVisible, 3000 + 3000 * hideAfter, { a: false, b: false }))
    }
    return () => {
      timeouts.forEach(t => clearTimeout(t))
    }
  }, [hideAfter])

  const curvePoint = x => (top ? `${x.x},${x.y + distance / flattenCurve}` : `${x.x + distance / flattenCurve},${x.y}`)

  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}>
      <Country show={pointsVisible.a} key={countryA.name} x={countryA.x} y={countryA.y}>
        <Signal power={power} show={pointsVisible.a} left country={countryA.name} service={countryA.service} />
      </Country>
      <Country show={pointsVisible.b} key={countryB.name} x={countryB.x} y={countryB.y}>
        <Signal power={power} show={pointsVisible.b} right country={countryB.name} service={countryA.service} />
      </Country>
      <svg viewBox={`0 0 ${scene.width} ${scene.height}`}>
        <path
          filter='url(#blur)'
          id={id}
          d={`M${countryA.x},${countryA.y} C${curvePoint(countryA)} ${curvePoint(countryB)} ${countryB.x},${
            countryB.y
          }`}
          stroke={`url(#gradient-${gradientDirection})`}
          fill='none'
        />
        <path
          ref={ref}
          id={id}
          d={`M${countryA.x},${countryA.y} C${curvePoint(countryA)} ${curvePoint(countryB)} ${countryB.x},${
            countryB.y
          }`}
          stroke={`url(#gradient-${gradientDirection})`}
          fill='none'
        />
        <style>{`
        #${id} {
          opacity: 0;
          ${pointsVisible.a || pointsVisible.b ? `animation: dash${id} 3s ease-in-out infinite 3s;` : ''}
          stroke-dasharray: ${len};
          stroke-dashoffset: ${len};
          stroke-width: 1px;
        }
        @keyframes dash${id} {
          from {
            opacity: 1;
            stroke-dashoffset: ${len};
          }
          to {
            opacity: 1;
            stroke-dashoffset: -${len};
          }
        }
      `}</style>
      </svg>
    </div>
  )
}
export default Connection
