// vendor modules
import styled from 'styled-components'

export const StyledHeader = styled.div`
  /* background: ${props => props.theme.color.primary}; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction:  column;
`

export const Actions = styled.div`
  margin-top: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  a:not(:last-child), button:not(:last-child) {
    margin-right: .5rem;
  }
`

export const Fake = styled.div`
  height: 150px;
`

export const TextWrapper = styled.div`
  text-align: center;
`

export const Title = styled.h1`
  color: #fff;
  font-size: 68px;
  font-weight: 600;
  line-height: 80px;
`

export const Subtitle = styled.em`
  color: #fff;
  font-style: normal;
`
