// vendor modules
import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
// components
import { Container } from 'components/common'
import { GradientActionButton } from 'components/common/Button'
import { Titleh1 as Title } from 'components/common/Title'
import { Feature } from 'components/common/Pricing/Feature'
import { ActionOuter, ActionInner, FeatureColumn, Price, PriceCategory, Pricing, Outer, StyledFeatureList, StyledInitialFeatureList } from './styles'

const price = 9.95

export default () => (
  <Outer as={Container}>
    <Title><FormattedMessage id='dns.pricing.title' /></Title>
    <Pricing>
      <StyledFeatureList>
        <FeatureColumn>
          <StyledInitialFeatureList>
            <Feature><FormattedMessage id='dns.pricing.benefit.faultTolerance' /></Feature>
            <Feature><FormattedMessage id='dns.pricing.benefit.highPerformance' /></Feature>
          </StyledInitialFeatureList>
          <Price><FormattedNumber value={price} currency='EUR' style={`currency`} /></Price>
          <PriceCategory><FormattedMessage id='dns.pricing.perMilReq' /></PriceCategory>
        </FeatureColumn>
        <FeatureColumn>
          <StyledInitialFeatureList>
            <Feature><FormattedMessage id='dns.pricing.benefit.sla' /></Feature>
            <Feature><FormattedMessage id='dns.pricing.benefit.geoRecords' /></Feature>
          </StyledInitialFeatureList>
          <Price><FormattedNumber value={price} currency='EUR' style={`currency`} /></Price>
          <PriceCategory><FormattedMessage id='dns.pricing.perDomainMonthly' /></PriceCategory>
        </FeatureColumn>
      </StyledFeatureList>
      <ActionOuter>
        <ActionInner>
          <GradientActionButton to=''><FormattedMessage id='action.orderNow' /></GradientActionButton>
        </ActionInner>
      </ActionOuter>
    </Pricing>
  </Outer>
)
