// vendor modules
import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 2rem;

  &> * {
    opacity: 0.8;
  }

  svg {
    margin-right: 1rem;
  }
`
