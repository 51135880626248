// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FaRegMap } from 'react-icons/fa'
// components
import { Titleh1 } from 'components/common/Title'
import { KPIList, Outer } from './styles'
import KPI from './KPI'

export default () => {
  return (
    <Outer>
      <Titleh1><FormattedMessage id='manager.dashboard.title' /></Titleh1>
      <KPIList>
        <KPI
          icon={<FaRegMap />}
          label='manager.dashboard.kpi.dns'
          to='/manager/dns'
          value={4}
        />
        <KPI
          icon={<FaRegMap />}
          label='manager.dashboard.kpi.dns'
          to='/manager/dns'
          value={4}
        />
        <KPI
          icon={<FaRegMap />}
          label='manager.dashboard.kpi.dns'
          to='/manager/dns'
          value={4}
        />
      </KPIList>
    </Outer>
  )
}
