// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
// components
import Pill, { TYPE_GREY_1, TYPE_GREY_2 } from 'components/common/Pill'
import User from './User'
import { Outer, StyledInner } from './styles'

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ bottom: '400px', left: '150px' }} type={TYPE_GREY_1} width='290px' />
        <Pill height='110px' position={{ bottom: '350px', left: '550px' }} type={TYPE_GREY_2} width='350px' />
        <Pill height='110px' position={{ bottom: '150px', left: '-150px' }} type={TYPE_GREY_1} width='780px' />
        {' '}
        <StyledInner pose={shouldAnimate ? 'animated' : 'idle'}>
          <User position={{ top: '50px', left: '0px' }} ip='178.62.33.121' />
          <User position={{ top: '150px', right: '250px' }} ip='178.09.00.253' />
          <User position={{ bottom: '100px', right: '350px' }} ip='178.00.00.146' />

          <User position={{ top: '70px', right: '100px' }} />
          <User position={{ top: '180px', left: '100px' }} />
          <User position={{ bottom: '0px', left: '0px' }} />
        </StyledInner>
      </Outer>
    </VisibilitySensor>
  )
}
