import React from 'react'
import {
  Switch,
  Route
} from 'react-router-dom'

// Pages
import DNS from 'pages/dns'
import VPN from 'pages/vpn'
import Support from 'pages/support'
import ManagerDashboard from 'pages/manager_dashboard'

const Routes = props => (
  <Switch>
    <Route path="/" exact>
      <DNS />
    </Route>
    <Route path="/dns">
      <DNS />
    </Route>
    <Route path="/vpn">
      <VPN />
    </Route>
    <Route path="/support">
      <Support />
    </Route>
    <Route path="/manager">
      <ManagerDashboard />
    </Route>
  </Switch>
)

export default Routes
