// vendor modules
import styled from 'styled-components'

export const Actions = styled.div`
  display: flex;
  margin-top: 4rem;
`

export const Container = styled.div`
  position: relative;
  width: 20%;
`

export const Header = styled.div`
  position: relative;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemList = styled.ul`
  list-style: none;
  margin: 0;
`

export const Item = styled.li`
  margin: 0;

  svg {
    width: 23px;
    height: 23px;
    margin-right: 1.5rem;
    color: #babdc7;
  }

  a {
    border-left: 3px solid transparent;
    color: ${props => props.theme.color.primary};
    display: block;
    font-weight: 500;
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
    transition: all .3s;

    &:hover {
      background: linear-gradient(to right, rgba(45,89,236,0.1) 0%,rgba(45,89,236,0) 100%);
      border-left: 3px solid ${props => props.theme.color.icon.secondary};
      color: ${props => props.theme.color.icon.secondary};

      svg {
        color: ${props => props.theme.color.icon.secondary};
      }
    }

    &.active {
      background: linear-gradient(to right, rgba(45,89,236,0.1) 0%,rgba(45,89,236,0) 100%);
      border-left: 3px solid ${props => props.theme.color.icon.secondary};
      color: ${props => props.theme.color.icon.secondary};

      svg {
        color: ${props => props.theme.color.icon.secondary};
      }
    }
  }
`

export const Logo = styled.div`
  svg {
    width: 261px;
    height: 21px;
  }
`

export const Navbar = styled.nav`
  margin-top: 10rem;
`
