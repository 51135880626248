// vendor modules
import React from 'react'
// components
import { Outer } from './styles'
import StepEmail from './StepEmail'

const STEP_EMAIL = 1
// const STEP_PASSWORD = 2
// const STEP_ACCOUNT = 3

class LoginForm extends React.Component {
  state = {
    fields: [],
    loading: true,
    step: STEP_EMAIL
  }

  renderStep () {
    if (this.state.step === STEP_EMAIL) {
      return <StepEmail />
    }
  }

  render () {
    return (
      <Outer>
        {this.renderStep()}
      </Outer>
    )
  }
}

export default LoginForm
