// vendor modules
import styled from 'styled-components'
// components
import { Titleh1 } from 'components/common/Title'

export const Title = styled(Titleh1)`
  text-align: center;
  margin-bottom: 4rem;
`

export const Wrapper = styled.div`
  margin: 6rem auto 15vh;
`
