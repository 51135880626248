// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa'
import { Container, Layout } from 'components/common'
import SmallHeader from 'components/common/SmallHeader'
import { GreenActionButton } from 'components/common/Button'
import Footer from 'components/common/Footer'
import Warning from 'components/common/Message/Warning'
import TwoColumnActions from 'components/common/TwoColumnActions'
import Background from 'components/common/Background/Grey'
import ArticleList from 'components/common/ArticleList/MostRecent'
import SearchInput from 'components/common/SearchInput'

const Inner = styled.div`
  margin: 5rem auto;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`

const actions = [
  {
    icon: 'kb',
    title: 'support.kb.title',
    description: 'support.kb.description',
    to: '/support/kb'
  },
  {
    icon: 'news',
    title: 'support.news.title',
    description: 'support.news.description',
    to: '/support/news'
  },
  {
    icon: 'contact',
    title: 'support.contact.title',
    description: 'support.contact.description',
    to: '/support/contact'
  },
  {
    icon: 'guide',
    title: 'support.guides.title',
    description: 'support.guides.description',
    to: '/support/guides'
  }
]

// function formatSuggestions (data) {
//   return data.map(item => ({
//     label: item.node.frontmatter.title,
//     to: `support/${item.node.frontmatter.contentType}/${item.node.frontmatter.category}/${item.node.frontmatter.pathname}`
//   }))
// }

// const suggestions = formatSuggestions(data.allMarkdownRemark.edges)
// Should be filled via an API call
const suggestions = []
// const articleList = data.allMarkdownRemark.edges
// Should be filled via an API call
const articleList = []

const SupportPage = ({ classes, data }) => (
  <Layout>
    <SmallHeader
      title='support.header.title'
    >
      <SearchWrapper>
        <SearchInput suggestions={suggestions} placeholder='support.search.placeholder' />
        <GreenActionButton><FaSearch /> <FormattedMessage id='action.search' /></GreenActionButton>
      </SearchWrapper>
    </SmallHeader>
    <Inner as={Container}>
      <Warning>We've been notified of VPN performance issues in Berlin, Germany. We're currently investigating the issues.<br />Check our Twitter feed or the news for the latest minute-to-minute updates.</Warning>
    </Inner>
    <Inner as={Container}>
      <TwoColumnActions actions={actions} />
    </Inner>
    <Background>
      <ArticleList data={articleList} />
    </Background>
    <Footer />
  </Layout>
)

export default SupportPage
