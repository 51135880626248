// vendor modules
import styled from 'styled-components'
// components
import Link from '../../Link'
import { Titleh3 } from '../../Title'

export const ActionLink = styled(Link)`
  display: block;
  font-size: 22px;
  font-weight: 600;
  height: 250px;
  padding: 3rem 2rem;
  text-decoration: none;
  transition: box-shadow .5s;
  width: 50%;
  border-bottom: 1px solid #edeef1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  &:hover {
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  }
  &:nth-child(odd) {
    border-right: 1px solid #edeef1;
  }
  &:nth-last-child(2), &:last-child {
    border-bottom: 1px solid transparent;
  }
`

export const ContentWrapper = styled.div`
  width: 70%;
`

export const Icon = styled.div`
  width: 30%;
`

export const IconWrapper = styled.div`

`

export const StyledDescription = styled.p`
  color: ${props => (props.theme.color.primary)};
  font-size: 18px;
  font-weight: 500;
`

export const StyledTitle = styled(Titleh3)`
  color: ${props => (props.theme.color.primary)};
`
