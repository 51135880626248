// vendor modules
import React from 'react'
import styled from 'styled-components'

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
  }
`

export default () => (
  <Loader>
    <img src='/loader.gif' alt='' />
  </Loader>
)
