export default {
  color: {
    primary: '#192344',
    bg: {
      grey: '#fbfbfc',
      grey2: '#f1f2f4'
    },
    icon: {
      primary: '#2d3eb1',
      secondary: '#2d59ec',
      green: '#01dc3f',
      red: '#df4c58'
    },
    button: {
      primary: '#161e37',
      secondary: '#2d3db0',
      blue: '#4558ba'
    },
    gradient: {
      primary: 'linear-gradient(102deg, rgb(22, 31, 54) 0%, #2d3eb1 78%, #2d3eb1 100%)',
      grey: 'linear-gradient(to bottom, #ffffff 0%, #f1f2f4 100%)',
      grey2: 'linear-gradient(to top, #ffffff 0%, #f1f2f4 100%)'
    }
  },
  mui: {
    palette: {
      parimary: {
        main: '#192344'
      },
      secondary: {
        main: '#00dc51'
      }
    },
    typography: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      htmlFontSize: 17,
      useNextVariants: true
    }
  }
}
