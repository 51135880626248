// vendor modules
import styled from 'styled-components'

export const Outer = styled.div`
  background: #fafbfb;
  padding: 2.5rem;
  display: flex;
  justify-content: space-between;
`

export const Icon = styled.div`
  background: #e5fbeb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 0.5rem;

  svg {
    color: #1ee055;
  }
`

export const Label = styled.div`
  background: #fafbfb;
`

export const Left = styled.div`
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Value = styled.div`
  background: #fafbfb;
  font-size: 42px;
  font-weight: 600;
  margin-top: 1rem;
`
