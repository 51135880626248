// components
import getRandomNumber from './randomNumber'
const getRandomItem = items => items[Math.floor(Math.random() * items.length)]
const services = ['Netflix', 'Torrent', 'Censure bypass', 'Skype']

const countries = {
  PL: [1044, 345, 'Poland', getRandomItem(services), getRandomNumber(1, 3)],
  DE: [1000, 350, 'Germany', getRandomItem(services), getRandomNumber(1, 3)],
  JP: [1710, 458, 'Japan', getRandomItem(services), getRandomNumber(1, 3)],
  FR: [1030, 200, 'France', getRandomItem(services), getRandomNumber(1, 3)]
}

export default countries
