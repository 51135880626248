// vendor modules
import React from 'react'
// components
import Action from './Action'
import { Outer } from './styles'

export default ({ actions }) => (
  <Outer>
    {actions.map((action, idx) => <Action key={idx} {...action} />)}
  </Outer>
)
