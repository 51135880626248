// vendor modules
import React from 'react'

const SvgFilters = () => (
  <svg>
    <linearGradient id='gradient-l2r'>
      <stop offset='0' stopColor='#fff' stopOpacity='0' />
      <stop offset='1' stopColor='#fff' stopOpacity='1' />
    </linearGradient>
    <linearGradient id='gradient-r2l'>
      <stop offset='0' stopColor='#fff' stopOpacity='1' />
      <stop offset='1' stopColor='#fff' stopOpacity='0' />
    </linearGradient>
    <filter id='blur'>
      <feGaussianBlur in='SourceGraphic' stdDeviation='2,2' />
    </filter>
  </svg>
)

export default SvgFilters
