// vendor modules
import styled from 'styled-components'

// 1:1 map image size 2000 / 1063
// coutries position should be re-scaled
export const realMapSize = { width: 2000, height: 1063 }

export const mapSize = 999 // %
// Focus point in %
export const focusPoint = {
  x: 65,
  y: 40
}

export const centerPoint = {
  x: focusPoint.x / 100,
  y: focusPoint.y / 100
}

export const SignalContainer = styled.div`
  position: absolute;
  transform: translateY(-30%);
  ${({ left }) =>
    left &&
    `
    right: 40px;
    text-align: right;
  `}
  ${({ right }) =>
    right &&
    `
    left: 40px;
  `}
`

export const Bars = styled.div`
  clear: both;
  margin-bottom: 7px;
  overflow: hidden;
  display: flex;
  justify-content: ${props => (props.right ? 'flex-start' : 'flex-end')};
  align-items: flex-end;
`

export const Bar = styled.div`
  width: 5px;
  border-radius: 4px;
  height: ${({ height }) => `${height}px`};
  background: ${({ on }) => (on ? 'green' : 'grey')};
  & + & {
    margin-left: 3px;
  }
`

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const CountryName = styled.div`
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
`

export const Service = styled.div`
  line-height: 1;
  font-size: 13px;
  opacity: 0.8;
  white-space: nowrap;
`

export const Country = styled.div`
  opacity: ${props => (props.show ? '1' : '0')};
  position: absolute;
  left: ${props => props.x || 0}px;
  top: ${props => props.y || 0}px;
  background: white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: ${props =>
    props.show
      ? '0 0 0 6px rgba(255, 255, 255, 0.2), 0 0 0 12px rgba(255, 255, 255, 0.1)'
      : '0 0 0 0px rgba(255, 255, 255, 0.2), 0 0 0 0px rgba(255, 255, 255, 0.1)'};
  transition: 1s all, opacity 1s ease-in-out 0s, box-shadow 1.5s ease-in-out 1s;
`

export const Map = styled(Container)`
  background: #212e70;
  background: radial-gradient(circle at ${focusPoint.x}% ${focusPoint.y}%, #212e70 0%, #1b264f 60%);
  color: white;
  overflow: hidden;
  /* Gradient used to fade out map and points */
  /*  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: radial-gradient(circle at ${focusPoint.x}% ${focusPoint.y}%, rgba(255, 255, 255, 0) 0%, #1b264f 90%);
  }*/
`

export const FadeBg = styled(Container)`
  z-index: 1;
  background: radial-gradient(circle at ${focusPoint.x}% ${focusPoint.y}%, rgba(255, 255, 255, 0) 0%, #1b264f 90%);
`

export const Image = styled.img`
  max-width: ${mapSize}%;
  transform: translate(${props => props.x || 0}px, ${props => props.y || 0}px);
  transition: opacity .5s;
  opacity: ${props => (props.show ? 1 : 0)};
  ${props => props.transition && 'transition: 1s all;'}
`

export const Decoration = styled.div`
  background: ${props =>
    props.light
      ? `linear-gradient(${props.reverse ? '-' : ''}102deg, #2737a3 78%,#2d3eb1 100%)`
      : `linear-gradient(${props.reverse ? '-' : ''}102deg, #1A264F, #2d3eb1)`};
  border-radius: 999px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: absolute;
  z-index: 2;
  top: ${props => props.top}px;
  right: ${props => props.right}px;
`

export const Connections = styled(Container)`
  /* 0 will make map points to go under the fade bg */
  z-index: 0;
`
