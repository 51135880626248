// vendor modules
import React from 'react'
// components
import Pill, { TYPE_DARK, TYPE_DARKER, TYPE_LIGHT, TYPE_LIGHTER } from 'components/common/Pill'
import { FadeBg, MapWrapper, Map, Image } from './styles'

export default () => {
  return (
    <MapWrapper>
      <Map id='map'>
        <FadeBg />
        <Image src='/map.png' />
        <Pill height='60px' position={{ top: '130px', right: '70px' }} type={TYPE_LIGHTER} width='160px' />
        <Pill height='60px' position={{ top: '160px', right: '300px' }} type={TYPE_DARK} width='270px' />
        <Pill height='60px' position={{ top: '280px', right: '-30px' }} type={TYPE_LIGHT} width='400px' />
        <Pill height='60px' position={{ bottom: '130px', right: '-30px' }} type={TYPE_DARKER} width='180px' />
        <Pill height='60px' position={{ bottom: '80px', left: '80px' }} type={TYPE_LIGHTER} width='160px' />
      </Map>
    </MapWrapper>
  )
}
