// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
// components
import { TabLink } from './styles'

const Tab = ({ title, to }) => (
  <TabLink activeClassName='active' to={to}><FormattedMessage id={title} /></TabLink>
)

export default Tab
