// vendor modules
import styled from 'styled-components'

export const Wrapper = styled.footer`
  background: #fff;
  border-top: 1px solid #f1f2f4;
`

export const Columns = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6rem 4rem;
  border-bottom: 1px solid #f1f2f4;
`

export const Fake = styled.div`
  width: 15rem;
`

export const LinkColumn = styled.div`

`

export const Links = styled.ul`
  list-style: none;
  margin: 0;
`

export const Link = styled.li`
  a {
    border-bottom: 2px solid transparent;
    color: ${props => (props.theme.color.button.primary)} !important;
    font-weight: 500;
    text-decoration: none;
    padding-bottom: 3px;
    transition: border-color .2s;
    &:hover {
      border-color: ${props => (props.theme.color.button.primary)};
    }
  }
`

export const Title = styled.h6`
  font-size: 24px;
  font-weight: 600;
`

export const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 4rem;
`

export const Copyright = styled.div`
  color: #babdc7;
  font-weight: 400;
`

export const PaymentMethods = styled.div`
  svg {
    margin-left: 1rem;
    opacity: 0.3;
    transition: opacity .5s;
    &:hover {
      opacity: 1;
    }
  }
`
