// vendor modules
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'

export const Container = styled.div`
  width: 80%;
  background: #f2f3f5;
`

export const Content = styled.div`
  padding: 2rem 5rem;
`

export const Header = styled.header`
  padding: 2rem 3rem;
  border-bottom: 1px solid #e8e9ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Hello = styled.span`
  color: #797e8f;
`

export const Search = styled.div`
  width: 30%;
  svg {
    color: ${props => props.theme.color.icon.secondary};
  }
`

export const StyledAvatar = styled(Avatar)`
  margin-right: 1rem;
`

export const User = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    color: ${props => props.theme.color.icon.secondary};
    width: 10px;
  }
`

export const UserName = styled.span`
  color: ${props => props.theme.color.primary};
  padding: 0 7px;
`
