// vendor modules
import styled from 'styled-components'

export const Outer = styled.div`

`

export const KPIList = styled.div`
  column-count: 3;
`
