// vendor modules
import React from 'react'
import Modal from 'react-modal'
import T from 'prop-types'
import { IoMdClose } from 'react-icons/io'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// components
import auth from './Auth'
import { LogoWrapper } from './styles'
import { ReactComponent as Logo } from 'images/logo.svg'

const MODALS = {
  auth
}

export default class ModalProvider extends React.PureComponent {
  static propTypes = {
    children: T.node.isRequired
  }

  static childContextTypes = {
    openModal: T.func.isRequired
  }

  state = {
    modal: null
  }

  disableScroll = null
  lastModal = ''

  getChildContext() {
    return {
      openModal: (modalName) => {
        window.scrollTo(0, 0)
        disableBodyScroll()

        this.lastModal = modalName
        this.setState({ modal: modalName })
      }
    }
  }

  async componentDidMount() {
    this.el = document.getElementById('modal-root')
    Modal.setAppElement(this.el)
  }

  closeModal = () => {
    enableBodyScroll()
    this.setState({ modal: null })
  }

  render() {
    const ModalContent = MODALS[this.state.modal]
    return (
      <React.Fragment>
        <Modal
          ariaHideApp={false}
          closeTimeoutMS={300}
          isOpen={!!this.state.modal}
          onRequestClose={() => this.setState({ modal: null })}
          overlayClassName={this.lastModal || ''}
        >
          {!!this.state.modal &&
            <div className='ReactModal__CloseButton'>
              <IoMdClose className='ReactModal__CloseButton_Button' onClick={this.closeModal} />
            </div>
          }
          {!!this.state.modal &&
            <React.Fragment>
              <LogoWrapper>
                <Logo width='261px' />
              </LogoWrapper>
              <ModalContent name={this.state.modal} />
            </React.Fragment>
          }
        </Modal>
        {this.props.children}
      </React.Fragment>
    )
  }
}
