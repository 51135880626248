// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'

const Button = posed.div({
  animated: { opacity: 1, scale: 1, transition: { type: 'spring' } },
  idle: { opacity: 0, scale: 0 }
})

export const Fader = styled.div`
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
  position: absolute;
  height: 75%;
  width: 100%;
  bottom: 0;
`

export const StyledButton = styled(Button)`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => {
    switch (props.color) {
      case 'red':
        return '#de4d58'
      case 'green':
        return '#01dc3f'
      default:
        return '#babdc7'
    }
  }};
  &:not(:last-child) {
    margin-right: 5px;
  }
`

export const Content = styled.div`
  background: #fff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 20px;
  font-weight: 500;
  padding: 1.5rem;
`

const Header = posed.div({
  animated: {
    delayChildren: 200,
    staggerChildren: 150
  },
  idle: { delay: 800 }
})

export const StyledHeader = styled(Header)`
  background: #f1f2f4;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 10px 15px;
`

const Outer = posed.div({
  animated: {
    opacity: 1,
    x: 0,
    transition: { duration: 800 }
  },
  idle: { delay: 300, opacity: 0, x: 10 }
})

export const StyledOuter = styled(Outer)`
  width: 460px;
  border-radius: 0.5rem;
  ${props =>
    props.fade ? 'box-shadow: 0 -30px 30px -25px rgba(0, 0, 0, 0.12)' : 'box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05)'}
`
