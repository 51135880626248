// vendor modules
import React from 'react'
// components
import { Container } from './styles'

const UserPanelLayout = ({ children }) => (
  <Container>
    {children}
  </Container>
)

export default UserPanelLayout
