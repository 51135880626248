// vendor modules
import { applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { history } from './reducers'

export default function createMiddleware () {
  const enhancers = [
    applyMiddleware(
      thunkMiddleware,
      routerMiddleware(history),
    )
  ]

  if (process.browser && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension())
  }

  return compose(...enhancers)
}
