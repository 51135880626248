// vendor modules
import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as CheckMark } from 'images/check.svg'

const Benefits = styled.div`
    padding: 4.5rem 0;
    columns: 10vw 3;
    margin: 0 auto;
    width: 60vw;
    line-height: 4vh;
`

const Benefit = styled.div`
    display: flex;
    align-items: center;
    svg {
        fill: #00dc3e;
        width: 19px;
        height: 19px;
        margin-right: 1rem;
    }
`

export default ({ list }) => {
  return (
    <Benefits>
      {list && list.map(benefit =>
        <Benefit key={benefit}><CheckMark /> <FormattedMessage id={benefit} /></Benefit>
      )}
    </Benefits>
  )
}
