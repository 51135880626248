// vendor modules
import styled from 'styled-components'
import positionMixin from '../../../../util/positionMixin'
import posed from 'react-pose'

const AnimatedPhone = posed.img({
  animated: {
    x: 0,
    scale: 1,
    opacity: 1
  },
  idle: {
    x: -80,
    scale: 0.5,
    opacity: 0
  }
})

export const Phone1 = styled(AnimatedPhone)(positionMixin)

export const Phones = posed.div({
  animated: {
    delayChildren: 600,
    staggerChildren: 700
  },
  idle: {}
})
