// vendor modules
import styled from 'styled-components'

export const Wrapper = styled.div`
    background: ${props => (props.theme.color.bg.grey2)};
    padding: 8rem 0;
`

export const Content = styled.div`
    text-align: center;
`

export const Benefits = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4rem;
`

export const Benefit = styled.div`
    width: 30%;
    text-align: left;
`

export const BenefitContent = styled.div`
    margin-top: 2rem;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
`

export const Icon = styled.div`
    background: #fff;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Num = styled.div`
    font-size: 120px;
    font-weight: 700;
    margin-left: 3rem;
    color: #babdc7;
    opacity: 0.2;
`
