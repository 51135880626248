// vendor modules
import React from 'react'
import T from 'prop-types'
// components
import { Content, Tabs, Tab, Title } from './styles'
import { NormalText } from '../../Title'

const VerticalTab = props => (
  <Tabs>
    {props.tabs.map((tab, idx) =>
      <Tab key={idx}>
        <input type='radio' name='sections' id={`tab-${idx}`} defaultChecked={idx === 0} />
        <Title htmlFor={`tab-${idx}`}>{tab.title}</Title>
        <Content><NormalText>{tab.content}</NormalText></Content>
      </Tab>
    )}
  </Tabs>
)

VerticalTab.propTypes = {
  tabs: T.array.isRequired
}

export default VerticalTab
