// vendor modules
import React from 'react'
import T from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { FaRegUser, FaToolbox } from 'react-icons/fa'
// components
import { ActionBasicButton, GradientButton } from 'components/common'
import Link from 'components/common/Link'
import { Actions, Navbar, Links, Logo } from './styles'
import  { ReactComponent as LogoVector }  from 'images/logo.svg'

export default class Header extends React.PureComponent {
  static contextTypes = {
    openModal: T.func.isRequired
  }

  handleLoginClick = (e) => {
    e.preventDefault()
    this.context.openModal('auth')
  }

  render() {
    return (
      <Navbar>
        <Logo as={Link} to='/'>
          <LogoVector />
        </Logo>
        <Links>
          <Link to='/dns' activeClassName='active' partiallyActive>
            <FormattedMessage id='navbar.dns' />
            <span className='slider' />
          </Link>
          <Link to='/vpn' activeClassName='active' partiallyActive>
            <FormattedMessage id='navbar.vpn' />
            {/* <FaChevronDown /> */}
            <span className='slider' />
          </Link>
          <Link to='/support' activeClassName='active' partiallyActive>
            <FormattedMessage id='navbar.support' />
            <span className='slider' />
          </Link>
        </Links>
        <Actions>
          <ActionBasicButton onClick={this.handleLoginClick}><FaRegUser /> <FormattedMessage id='action.signUp' /></ActionBasicButton>
          <GradientButton to='/manager/dashboard'><FaToolbox /> <FormattedMessage id='action.manager' /></GradientButton>
        </Actions>
      </Navbar>
    )
  }
}
