// vendor modules
import styled from 'styled-components'
// components
import Link from '../../../Link'

export const TabLink = styled(Link)`
  background: ${props => props.theme.color.button.primary};
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  color: ${props => props.theme.color.button.blue};
  display: block;
  font-size: 22px;
  font-weight: 600;
  padding: 1.5rem 2rem;
  text-decoration: none;

  &:not(:last-child) {
      margin-right: .7rem;
  }

  &.active {
    background: #fff;
    color: ${props => props.theme.color.primary};
  }
`
