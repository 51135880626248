// vendor modules
import styled from 'styled-components'

export const MegaTitle = styled.h1`
  font-size: 68px;
  font-weight: 600;
  line-height: 90px;
`

export const Titleh1 = styled.h1`
  font-size: 52px;
  font-weight: 600;
  line-height: 65px;
`

export const Titleh2 = styled.h2`
  font-size: 42px;
  font-weight: 600;
  text-align: center;
`

export const Titleh3 = styled.h3`
  font-size: 28px;
  font-weight: 700;
`

export const Titleh6 = styled.h6`
  font-size: 22px;
  font-weight: 600;
`

export const NormalText = styled.p`
  line-height: 35px;
`

export const SmallText = styled.p`
  font-size: 16px;
  font-weight: 500;
`
