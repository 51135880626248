const waitForGlobal = (key, callback) => {
  if (window[key]) {
    callback()
  } else {
    setTimeout(() => {
      waitForGlobal(key, callback)
    }, 15)
  }
}

const FW = () => {
  return new Promise(resolve => {
    waitForGlobal('FW', () => {
      resolve(window.FW)
    })
  })
}

export default FW
