// vendor modules
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import Pill, { TYPE_GREY_1 } from 'components/common/Pill'
import { Inner, Outer } from '../../DNS/FaultTolerance/styles'
import { Phone1, Phones } from './styles'

export default () => {
  const [shouldAnimate, setAnimate] = useState(false)
  return (
    <VisibilitySensor onChange={isVisible => isVisible && setAnimate(true)}>
      <Outer>
        <Pill height='110px' position={{ top: '250px', left: '20px' }} type={TYPE_GREY_1} width='250px' />
        <Pill height='110px' position={{ top: '500px', left: '-50px' }} type={TYPE_GREY_1} width='450px' />
        <Pill height='110px' position={{ top: '350px', left: '260px' }} type={TYPE_GREY_1} width='340px' />
        <Inner>
          <Phones pose={shouldAnimate ? 'animated' : 'idle'}>
            <Phone1 position={{ left: 0, top: 0 }} src='/icons/vpn-phone-1.png' />
            <Phone1 position={{ left: 200, top: -70 }} src='/icons/vpn-phone-1.png' />
          </Phones>
        </Inner>
      </Outer>
    </VisibilitySensor>
  )
}
