// vendor modules
import React from 'react'
import T from 'prop-types'
import styled from 'styled-components'
import positionMixin from '../../../util/positionMixin'

export const TYPE_DARK = 1
export const TYPE_DARKER = 2
export const TYPE_LIGHT = 3
export const TYPE_LIGHTER = 4
export const TYPE_GREY_1 = 5
export const TYPE_GREY_2 = 6

const gradientByType = {
  [TYPE_DARK]: 'linear-gradient(to right, rgba(26,36,72,1) 0%,rgba(41,58,160,1) 100%)',
  [TYPE_DARKER]: 'linear-gradient(to right, rgba(25,35,68,1) 0%,rgba(36,51,131,1) 100%)',
  [TYPE_LIGHT]: 'linear-gradient(to right, rgba(45,62,178,1) 0%,rgba(54,75,232,1) 100%)',
  [TYPE_LIGHTER]: 'linear-gradient(to right, rgba(31,112,243,1) 0%,rgba(5,153,255,1) 100%)',
  [TYPE_GREY_1]: 'linear-gradient(to right, rgba(250,251,251,1) 0%,rgba(243,244,246,1) 100%)',
  [TYPE_GREY_2]: 'linear-gradient(to right, rgba(246,248,248,0) 0%,rgba(246,248,248,1) 100%)'
}

const gradientMixin = ({ type }) => type && gradientByType[type] && `background: ${gradientByType[type]};`

const Container = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.height};
  z-index: 0;
  ${gradientMixin}
  ${positionMixin}
`

const Pill = props => <Container {...props} />

Pill.propTypes = {
  cut: T.string,
  height: T.string,
  position: T.object.isRequired,
  type: T.number.isRequired,
  width: T.string.isRequired
}

Pill.defaultProps = {
  cut: '',
  height: '64px'
}

export default Pill
