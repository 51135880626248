// vendor modules
import React from 'react'
import { Content, StyledButton, StyledHeader, StyledOuter, Fader } from './styles'

export default ({ children, shouldAnimate, color = 'red', fade = false }) => (
  <StyledOuter pose={shouldAnimate ? 'animated' : 'idle'} fade={fade}>
    <StyledHeader pose={shouldAnimate ? 'animated' : 'idle'}>
      <StyledButton color={color} />
      <StyledButton />
      <StyledButton />
    </StyledHeader>
    <Content>{children}</Content>
    {fade && <Fader />}
  </StyledOuter>
)
