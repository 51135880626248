// vendor modules
import React from 'react'
import T from 'prop-types'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
// components
import Header, { HeaderTextWrapper, HeaderTitle, HeaderSubtitle, HeaderActions } from 'components/common/Header'
import { ActionBasicButton, GradientActionButton } from 'components/common'
import Map from './Map'

export default class extends React.PureComponent {
  static contextTypes = {
    openModal: T.func.isRequired
  }

  static propTypes = {
    tabs: T.array
  }

  static defaultProps = {
    tabs: []
  }

  handleLoginClick = (e) => {
    e.preventDefault()
    this.context.openModal('auth')
  }

  scrollToContent = () => {
    document.querySelector('a[name="content"]').scrollIntoView({ behavior: 'smooth' })
  }

  render () {
    return (
      <Header map={<Map />} tabs={this.props.tabs}>
        <HeaderTextWrapper>
          <HeaderTitle><FormattedHTMLMessage id='dns.title' /></HeaderTitle>
          <HeaderSubtitle><FormattedHTMLMessage id='dns.subtitle' /></HeaderSubtitle>
        </HeaderTextWrapper>
        <HeaderActions>
          <ActionBasicButton onClick={this.handleLoginClick}><FormattedMessage id='action.getStarted' /></ActionBasicButton>
          <GradientActionButton onClick={this.scrollToContent}><FormattedMessage id='action.learnMore' /></GradientActionButton>
        </HeaderActions>
      </Header>
    )
  }
}
