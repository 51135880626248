// vendor modules
import styled from 'styled-components'

export const Outer = styled.div`
    position: relative;
    overflow: hidden;
`

export const Inner = styled.div`
    margin: 6rem auto 15vh;
    display: flex;
    align-items: center;
`

export const Content = styled.div`
    width: 40%;
    margin-left: ${props => (props.align === 'right' ? 'auto' : '0')};
    margin-right: ${props => (props.align === 'right' ? '0' : 'auto')};
    z-index: 1;
    svg {
        width: 10vw;
    }
`

export const Illustration = styled.div`
    width: 60%;
    height: 50vh;
`

export const Actions = styled.div`
    display: flex;
    margin-top: 7vh;
    a:not(:last-child) {
        margin-right: .5rem;
    }
`
