// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
// components
import { Container } from 'components/common'
import { NormalText, Titleh1 as Title, Titleh6 as Subtitle, SmallText } from 'components/common/Title'
import { Wrapper, Content, Benefits, Benefit, Header, Icon, Num, BenefitContent } from './styles'

export default () => {
  return (
    <Wrapper>
      <Content as={Container}>
        <Title><FormattedMessage id='home.professionalBenefits.title' /></Title>
        <NormalText><FormattedMessage id='home.professionalBenefits.subtitle' /></NormalText>
        <Benefits>
          <Benefit>
            <Header>
              <Icon><img alt='' src='/icons/scale.png' /></Icon>
              <Num>1</Num>
            </Header>
            <BenefitContent>
              <Subtitle><FormattedMessage id='home.professionalBenefits.scales.title' /></Subtitle>
              <SmallText><FormattedMessage id='home.professionalBenefits.scales.p' /></SmallText>
            </BenefitContent>
          </Benefit>
          <Benefit>
            <Header>
              <Icon><img alt='' src='/icons/reliable.png' /></Icon>
              <Num>2</Num>
            </Header>
            <BenefitContent>
              <Subtitle><FormattedMessage id='home.professionalBenefits.reliable.title' /></Subtitle>
              <SmallText><FormattedMessage id='home.professionalBenefits.reliable.p' /></SmallText>
            </BenefitContent>
          </Benefit>
          <Benefit>
            <Header>
              <Icon><img alt='' src='/icons/easy.png' /></Icon>
              <Num>3</Num>
            </Header>
            <BenefitContent>
              <Subtitle><FormattedMessage id='home.professionalBenefits.easy.title' /></Subtitle>
              <SmallText><FormattedMessage id='home.professionalBenefits.easy.p' /></SmallText>
            </BenefitContent>
          </Benefit>
        </Benefits>
      </Content>
    </Wrapper>
  )
}
