// vendor modules
import React from 'react'
import styled from 'styled-components'
import positionMixin from '../../../../util/positionMixin'
import posed from 'react-pose'
import { FormattedMessage } from 'react-intl'

const AnimatedLabel = posed.div({
  animated: {
    opacity: 1
  },
  idle: {
    opacity: 0.3
  }
})

const Label = styled(AnimatedLabel)`
  text-align: center;
  margin-top: 0.8em;
  font-weight: bold;
`

const AnimatedIdx = posed.div({
  animated: {
    background: '#01dc3f'
  },
  idle: {
    background: '#babcc5'
  }
})

const Idx = styled(AnimatedIdx)`
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  left: -10px;
  top: 0;
`

const AnimatedCircle = posed.div({
  animated: {
    border: '35px solid #fff',
    background: '#fff',
    filter: 'grayscale(0%)',
    delayChildren: ({ delay }) => delay
  },
  idle: {
    border: '35px solid #f1f2f4',
    background: '#f1f2f4',
    filter: 'grayscale(100%)'
  }
})

const Circle = styled(AnimatedCircle)`
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 120px;
  box-sizing: border-box;
`

const Delayer = posed.div({
  animated: {
    delayChildren: ({ delay }) => delay + 600
  },
  idle: {}
})

const AnimatedArrow = posed.img({
  animated: {
    opacity: 1,
    scale: 1
  },
  idle: {
    opacity: 0,
    scale: 0.5
  }
})

const Arrow = styled(AnimatedArrow)`
  position: absolute;
  ${({ arrow }) =>
    arrow === 'top'
      ? `
    top: -10px;
    right: -40px;
  `
      : `
    bottom: 0px;
    right: -40px;
  `}
`

const AnimatedWrapper = posed.div({
  animated: {
    opacity: 1,
    scale: 1,
    delayChildren: ({ num }) => num * 1200 + 3600
  },
  idle: {
    opacity: 0,
    scale: 0
  }
})

const Picture = posed.img({
  animated: {
    opacity: 1,
    scale: 1
  },
  idle: {
    opacity: 0.3,
    scale: 0.8
  }
})

const Wrapper = styled(AnimatedWrapper)`
  ${positionMixin}
`

const Icon = ({ position, type = 'download', num = 1, off = false, arrow = '' }) => {
  return (
    <Wrapper num={num} position={position}>
      <Circle>
        <Picture src={`/icons/vpn-${type}.png`} />
      </Circle>
      <Idx>{num}</Idx>
      <Label>
        <FormattedMessage id={`vpn.setup.${type}`} />
      </Label>
      {arrow && (
        <Delayer>
          <Arrow arrow={arrow} src={`/icons/arrow-right-${arrow}.png`} />
        </Delayer>
      )}
    </Wrapper>
  )
}

export default Icon
