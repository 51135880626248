// vendor modules
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import smoothscroll from 'smoothscroll-polyfill'
// components
import theme from 'components/common/theme'
import ModalProvider from '../Modal'
import { Global } from './styles'

import './layout.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-typist/dist/Typist.css'

const muiTheme = createMuiTheme(theme.mui)

export const Layout = ({ children }) => {
  smoothscroll.polyfill()
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <ModalProvider>
            <Global>
              {children}
            </Global>
          </ModalProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}
