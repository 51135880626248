// vendor modules
import styled from 'styled-components'

export const StyledHeader = styled.div`
  height: ${props => props.height};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction:  column;
  min-height: 400px;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
`

export const Fake = styled.div`
  height: 150px;
`

export const Title = styled.h1`
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  line-height: 80px;
  padding-top: 5vh;
`

export const Icon = styled.div`
  background: ${props => props.theme.color.button.primary};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  img {
    max-width: 62px;
    max-height: 62px;
  }
`
