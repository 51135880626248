// vendor modules
import React, { useRef, useState, useEffect } from 'react'
import ContainerSize from 'react-container-dimensions'
// components
import countries from './countries'
import { centerPoint, FadeBg, Connections, Container, Map, Image, realMapSize } from './styles'
import getRandomNumber from './randomNumber'
import Connection from './Connection'
import Decorations from './Decorations'
import SvgFilters from './SvgFilters'

const focusCountry = 'PL'
const Updater = ({ width, height, callback }) => {
  useEffect(() => {
    callback(width, height)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height])
  return null
}

export default () => {
  const imageRef = useRef(null)
  const [imageLoaded, setLoaded] = useState(false)
  const [scale, setScale] = useState(1)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [countryX, countryY] = countries[focusCountry]

  const centerX = containerSize.width * centerPoint.x
  const centerY = containerSize.height * centerPoint.y

  const scaledUserCountry = { x: countryX / scale, y: countryY / scale }

  const mapOffset = {
    // calc offset / : starting point
    x: imageLoaded ? centerX - scaledUserCountry.x : 0,
    y: imageLoaded ? centerY - scaledUserCountry.y : 0
  }

  const stdCountries = imageLoaded
    ? Object.keys(countries).map(cname => ({
      code: cname,
      name: countries[cname][2],
      x: countries[cname][0] / scale + mapOffset.x,
      y: countries[cname][1] / scale + mapOffset.y,
      service: countries[cname][3],
      power: countries[cname][4]
    }))
    : []

  const handleImageLoad = (width, height) => () => {
    setLoaded(true)
    setScale(realMapSize.width / imageRef.current.clientWidth)
    setContainerSize({ width, height })
  }

  const [slot1, setSlot1] = useState(false)
  const [slot2, setSlot2] = useState(false)

  const slot1Loops = useRef(getRandomNumber(1, 10))
  const slot2Loops = useRef(getRandomNumber(1, 10))

  useEffect(() => {
    const timeouts = []
    const delay1 = getRandomNumber(1000, 2000)
    const delay2 = getRandomNumber(2500, 4000)

    timeouts.push(setTimeout(setSlot1, delay1, true))
    timeouts.push(
      setTimeout(() => {
        setSlot1(false)
        slot1Loops.current = getRandomNumber(3, 10)
      }, delay1 + 6000 + 3000 * slot1Loops.current)
    )
    timeouts.push(setTimeout(setSlot1, delay1 + 6000 + 3000 * slot1Loops.current + getRandomNumber(1000, 6000), true))

    timeouts.push(setTimeout(setSlot2, delay2, true))
    timeouts.push(
      setTimeout(() => {
        setSlot2(false)
        slot2Loops.current = getRandomNumber(3, 10)
      }, delay2 + 6000 + 3000 * slot2Loops.current)
    )
    timeouts.push(setTimeout(setSlot2, delay2 + 6000 + 3000 * slot2Loops.current, true))

    return () => {
      timeouts.forEach(t => clearTimeout(t))
    }
  }, [slot1Loops, slot2Loops])

  return (
    <Container id='map'>
      <ContainerSize>
        {({ width, height }) => (
          <Map>
            <Updater width={width} height={height} callback={(w, h) => handleImageLoad(w, h)()} />
            <Decorations width={width} height={height} />
            <FadeBg />
            <Image
              transition
              onLoad={handleImageLoad(width, height)}
              ref={imageRef}
              x={mapOffset.x}
              y={mapOffset.y}
              show={imageLoaded}
              src='/map.png'
            />
            <SvgFilters />
            {!!stdCountries.length && (
              <Connections>
                {slot1 && (
                  <Connection
                    power={stdCountries[1].power}
                    hideAfter={slot1Loops.current}
                    scene={{ width, height }}
                    countryA={stdCountries[1]}
                    countryB={stdCountries[0]}
                  />
                )}
                {slot2 && (
                  <Connection
                    power={stdCountries[2].power}
                    hideAfter={slot2Loops.current}
                    scene={{ width, height }}
                    countryA={stdCountries[2]}
                    countryB={stdCountries[3]}
                  />
                )}
              </Connections>
            )}
          </Map>
        )}
      </ContainerSize>
    </Container>
  )
}
