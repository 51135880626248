// vendor modules
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Slider from 'react-slick'
// components
import ArticleExtract from '../ArticleExtract'
import { Container } from 'components/common'
import { Titleh1 } from '../../Title'

const Outer = styled.div`
  text-align: center;
`

const Inner = styled.div`
  padding-top: 2rem;

  .slick-dots {
    bottom: -50px;
    
    &>li {
      button {
        background: #e9eaed;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        &::before {
          content: "";
        }
      }
      &.slick-active button {
        background: #1e71f3;
      }
    }
  }
`

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3
}

export default ({ data }) => (
  <Outer as={Container}>
    <Titleh1><FormattedMessage id='articleList.mostRecent.title' /></Titleh1>
    <Inner>
      <Slider {...settings}>
        {data.map(article => <ArticleExtract key={article.id} {...article} />)}
      </Slider>
    </Inner>
  </Outer>
)
