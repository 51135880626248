// vendor modules
import React from 'react'
import styled from 'styled-components'
import { FaExclamationTriangle } from 'react-icons/fa'
// components
import { Container } from '../styles'

const StyledContainer = styled(Container)`
  background: #fcedee;

  svg {
    color: #de4d58;
  }
`

export default (props) => (
  <StyledContainer {...props}>
    <FaExclamationTriangle /> <div>{props.children}</div>
  </StyledContainer>
)
