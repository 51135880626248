// vendor modules
import styled from 'styled-components'
import posed from 'react-pose'
import positionMixin from '../../../../util/positionMixin'

const CircleList = posed.div({
  animated: {
    delayChildren: 200,
    staggerChildren: 50
  },
  idle: { delay: 300 }
})
const Circle = posed.div({
  animated: { opacity: 1, scale: 1, transition: { type: 'spring' } },
  idle: { opacity: 0, scale: 0.8 }
})

export const StyledCircle = styled(Circle)`
  width: ${props => props.size};
  height: ${props => props.size};
  border: 1px solid #dee0e6;
  border-radius: 50%;
  opacity: ${props => props.opacity};
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const StyledCircleList = styled(CircleList)`
  position: relative;
  width: 600px;
  height: 600px;
`

export const Outer = styled.div`
  height: 100%;
`

export const Inner = styled.div`
  position: relative;
  height: 100%;
  right: -100px;
`

const TextBlock = posed.div({
  animated: { opacity: 1, scale: 1, x: 0, transition: { type: 'spring' } },
  idle: { opacity: 0, scale: 0, x: -50 }
})

export const StyledTextBlock = styled(TextBlock)`
  background: ${props => (props.colorTheme === 'green' ? '#e5fbeb' : '#fff')};
  color: ${props => (props.colorTheme === 'green' ? '#0fde4a' : props.theme.color.icon.primary)};
  padding: 0.7rem 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  ${positionMixin}
`
